import * as yup from 'yup';

import { VALIDATION_MESSAGE } from '@/definition/VALIDATION_MESSAGE';

export const YUP_VALIDATION = {
  null: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  required: yup
    .string()
    .matches(/.*\S+.*/, VALIDATION_MESSAGE.required)
    .nullable()
    .required(VALIDATION_MESSAGE.required),
  url: yup.string().nullable().url(VALIDATION_MESSAGE.url),
  furigana: yup
    .string()
    .matches(/.*\S+.*/, VALIDATION_MESSAGE.required)
    .nullable()
    .required(VALIDATION_MESSAGE.required)
    .matches(/^[ぁ-ゖー0-9!-/:-@¥[-`{-~・|\s]+$/, VALIDATION_MESSAGE.furigana),
  tel: yup
    .string()
    .matches(/^\d{10,11}$/, VALIDATION_MESSAGE.tel)
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  tel_required: yup
    .string()
    .matches(/^\d{10,11}$/, VALIDATION_MESSAGE.tel)
    .nullable()
    .required(VALIDATION_MESSAGE.required)
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  email: yup.string().nullable().email(VALIDATION_MESSAGE.email),
  email_required: yup
    .string()
    .nullable()
    .required(VALIDATION_MESSAGE.required)
    .email(VALIDATION_MESSAGE.email),
  message: (number_of_words: number): yup.StringSchema => {
    return yup.string().max(number_of_words, `${number_of_words}${VALIDATION_MESSAGE.maxString}`);
  },
  birth_year: yup
    .string()
    .required(VALIDATION_MESSAGE.required)
    .matches(/^([5-9]|[1-9][0-9]|[1-9][0-8][0-9]|990)$/, VALIDATION_MESSAGE.score), // 1900 - 2021
  password: yup
    .string()
    .required(VALIDATION_MESSAGE.required)
    .min(8, `8${VALIDATION_MESSAGE.minString}`)
    .matches(
      /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[A-Z])(?=.*[a-z])(?=.*[-_.])|(?=.*[a-z])(?=.*\d)(?=.*[-_.])|(?=.*[A-Z])(?=.*\d)(?=.*[-_.])|(?=.*[a-z])(?=.*\d)(?=.*[-_.]))([a-zA-Z\d-_.]){8,}$/,
      VALIDATION_MESSAGE.password_format
    ),
  re_password: (check_password_name = 'password'): yup.StringSchema => {
    return yup
      .string()
      .oneOf([yup.ref(check_password_name), null], VALIDATION_MESSAGE.password_confirmation);
  },
};
