import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React from 'react';
import { OnboardingPageWrapper } from './PageWrapper';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { PageDots } from './PageDots';
import {
    notificationError, toggleLoading
} from '@/redux/index';
import { MyNaviApi, MyNavi } from '@/utils/api-client';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import BaseButton from '@/components/common/Button/BaseButton';

type MyNaviItem = {
  mc_preferred_job_type: number,
  mc_preferred_yearly_income: number,
  mc_preferred_change_date: number
};

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  myNavi?: MyNavi;
};

export const OnboardingPage_3 = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();

  /**
   * validation 設定
   */
  const schema = yup.object().shape({
    mc_preferred_job_type: YUP_VALIDATION.required,
    mc_preferred_yearly_income: YUP_VALIDATION.required,
    mc_preferred_change_date: YUP_VALIDATION.required,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps: UseFormProps<any> = {
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      mc_preferred_job_type: props.myNavi?.mc_preferred_job_type || undefined,
      mc_preferred_yearly_income: props.myNavi?.mc_preferred_yearly_income || undefined,
      mc_preferred_change_date: props.myNavi?.mc_preferred_change_date || undefined,
    },
  };

  const methods = useForm(useFormProps);

  const { handleSubmit } = methods;

  // 送信処理
  const onSubmit = async (data: MyNaviItem) => {
    if (!data) return;

    dispatch(toggleLoading(true));
    const convertedData: any = {};
    (Object.keys(data) as (keyof MyNaviItem)[]).forEach((key) => {
      convertedData[key] = Number(data[key]);
    });

    try {
      await new MyNaviApi().patchMynaviCreator(convertedData);
    } catch (e) {
      dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      props.setPage(4);
    }
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_3'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <span>
            続いて、あなたが希望する職種、
            <br />
            希望の年収、転職希望時期を教えてください。
          </span>
        }
      >
        <div>
          <p className="mt-14 text-12_20">※この項⽬はあとから変更できます。</p>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset>
                  <FormLabel label="希望職種" type="small">
                  </FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items w-308"
                    name="mc_preferred_job_type"
                    placeholder="選択してください"
                    selectList={FORM_OPTIONS.preferred_job_type}
                  ></FormContainerDropdown>
                </FormLayoutFieldset>
              </div>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset>
                  <FormLabel label="希望年収" type="small"></FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items w-308"
                    name="mc_preferred_yearly_income"
                    placeholder="選択してください"
                    selectList={FORM_OPTIONS.yearly_income}
                  ></FormContainerDropdown>
                </FormLayoutFieldset>
              </div>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset>
                  <FormLabel label="転職希望時期" type="small"></FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items w-308"
                    name="mc_preferred_change_date"
                    placeholder="選択してください"
                    selectList={FORM_OPTIONS.change_date}
                  ></FormContainerDropdown>
                </FormLayoutFieldset>
              </div>
              <div className="pb-20 mb-48 sm:mb-72">
                <PageDots page={props.page} setPage={props.setPage} unClick className="mt-48" />
                <div className="w-188 m-auto mt-48">
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                  >
                    次へ
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
