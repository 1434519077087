import cn from 'classnames';
import React, { ReactNode, VFC } from 'react';

type TProps = {
  select?: boolean;
  className?: string;
  value: string;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  active?: boolean;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=393%3A40447
 */
export const FormDropdownMultipleItem: VFC<TProps> = ({
  select = false,
  className,
  value,
  children,
  onClick,
}) => {
  return (
    <li
      className={cn(className, 'mbx-dropdown-selections-item', 'text-14_20', 'px-10', 'py-14', {
        'is-select': select,
      })}
      onClick={onClick}
      data-value={value}
    >
      <i className="mbx-icon mbx-icon-Check"></i>
      <p>{children}</p>
    </li>
  );
};
