import React, { useMemo } from 'react';
import BaseButton from '../Button/BaseButton';
import cn from 'classnames';

type Props = {
  id: number;
  status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  statusUpdate: (id: number, status: number) => Promise<void>;
  admin?: boolean;
  rate?: 1 | 2 | 3;
};

function OfferStatus(props: Props): React.ReactElement {
  const rate = useMemo<string>(() => {
    switch (props.rate) {
      case 1:
        return 'マッチ度：低';
      case 2:
        return 'マッチ度：中';
      case 3:
        return 'マッチ度：高';
      default:
        return 'タグなし';
    }
  }, [props.rate]);

  const status = useMemo(() => {
    switch (props.status) {
      case 0:
        return {
          cn: 'bg-white text-gray-400',
          name: <>回答期限終了</>,
        };
      case 1:
        return {
          cn: 'bg-blue-300 text-blue-800',
          name: rate,
        };
      case 3:
        return {
          cn: 'bg-redGray-200 text-redGray-700',
          name: <>一次面接オファー回答待ち</>,
        };
      case 4:
        return {
          cn: 'bg-gray-200 text-gray-400',
          name: <>見送り</>,
        };
      case 5:
        return {
          cn: 'bg-gray-200 text-gray-400',
          name: <>見送り</>,
        };
      case 6:
        return {
          cn: 'bg-red-200 text-red-700',
          name: <>オファーに応諾</>,
        };
      case 7:
        return {
          cn: 'bg-attention-200 text-attention-700',
          name: <>マイナビワークスに相談中</>,
        };
      case 8:
        return {
          cn: 'bg-blueGray-200 text-blueGray-700',
          name: <>カジュアル面談オファー回答待ち</>,
        };
      case 9:
        return {
          cn: 'bg-blue-300 text-blue-800',
          name: <>オファーに応諾</>,
        };
      case 10:
        return {
          cn: 'bg-gray-200 text-gray-400',
          name: <>見送り</>,
        };
      case 11:
        return {
          cn: 'bg-attention-200 text-attention-700',
          name: <>マイナビワークスに相談中</>,
        };
    }
  }, [props.status, props.rate]);

  return (
    <section>
      {(props.status === 3 || props.status === 8) && !props.admin ? (
        <div className="flex sm:justify-center">
          <BaseButton
            theme="primary"
            size="ss"
            className="mbx-offer-btn"
            onClick={() => props.statusUpdate(props.id, props.status === 3 ? 6 : 9)}
          >
            応諾する
          </BaseButton>
          <BaseButton
            theme="secondary"
            size="ss"
            className="mbx-offer-btn ml-4"
            onClick={() => props.statusUpdate(props.id, props.status === 3 ? 7 : 11)}
          >
            マイナビワークスに相談
          </BaseButton>
          <BaseButton
            theme="secondary"
            size="ss"
            className="mbx-offer-btn ml-4"
            onClick={() => props.statusUpdate(props.id, props.status === 3 ? 5 : 10)}
          >
            見送る
          </BaseButton>
        </div>
      ) : (
        <div
          className={cn(
            'flex',
            'justify-center',
            'items-center',
            'h-30',
            'text-button_ss',
            'font-bold',
            'sm:h-36',
            status?.cn
          )}
        >
          <span>{status?.name}</span>
        </div>
      )}
    </section>
  );
}

export default OfferStatus;
