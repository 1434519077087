import '@/styles/common/Direct/Step2.scss';

import React from 'react';

import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { UserApi } from '@/utils/api-client';
import { useDispatch } from 'react-redux';
import { userInfoForceUpdate } from '@/redux/index';

import BaseButton from '../Button/BaseButton';
import BlockSection from './BlockSection';
import Indicator from './Indicator';
import RequestTitle from './RequestTitle';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';

type Props = {
  setStep: React.Dispatch<
    React.SetStateAction<{
      step1: boolean;
      step2: boolean;
      step3: boolean;
    }>
  >;
};

function Step2(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const mq = useMBXMediaQuery();

  const onRegister = async () => {
    const result = await new UserApi().postUserRegistrationDirect();
    if (result.status === 200) {
      const user = await new UserApi().getUser();
      if (user.status === 200) {
        if (user.data.user_info) dispatch(userInfoForceUpdate(user.data.user_info));
      }
      props.setStep({
        step1: false,
        step2: false,
        step3: true,
      });
    }
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        virtualPageName: 'step_2'
      }}
    >
    <section className="step2">
      <section className="flex justify-center mt-48">
        <Indicator step={2} />
      </section>
      <RequestTitle title="ブロックする企業の設定（任意）">
        <>
          あなたのポートフォリオを表示させない企業を設定できます。
          {!mq.sm && <br />}
          現在の勤務先や関連会社などを検索して設定してください。
        </>
      </RequestTitle>
      <BlockSection />
      <div className="w-624 m-auto pb-96 sm:w-auto sm:pb-212">
        <p className="mt-80 mbx-typography--body_1 text-center sm:mt-48">
          内容に間違いがないかご確認の上、登録するボタンを押してください。
        </p>
        <div className="flex justify-center mt-24">
          <BaseButton theme="primary" size="m" className="send-btn" onClick={onRegister}>
            オファー機能に登録する
          </BaseButton>
        </div>
        <div className="flex justify-center mt-24">
          <BaseButton
            theme="link"
            size="m"
            onClick={() => props.setStep({ step1: true, step2: false, step3: false })}
          >
            STEP1 経験・希望を確認しアップデートに戻る
          </BaseButton>
        </div>
        <div className="flex justify-center mt-80">
          <BaseButton theme="link" size="m" href="/mypage">
            登録をキャンセル
          </BaseButton>
        </div>
      </div>
    </section>
    </DataLayerPushContainer>
  );
}

export default Step2;
