import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Wrapper } from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { SectionPortfolio } from '@/components/Mypage/Setting/SectionPortfolio';
import { SectionOffer } from '@/components/Mypage/Setting/SectionOffer';
import { UserApi, BlockCompany, PortfolioApi, MatchboxUserResponse } from '@/utils/api-client';

/**
 * # マイページ 各種設定 /mypage/condition
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=487%3A7054
 *
 */
export const MypageSetting = (): React.ReactElement => {
  const [user, setUser] = useState<MatchboxUserResponse>();
  const [blockList, setBlockList] = useState<BlockCompany[]>([]);
  const [portfolioStatus, setPortfolioStatus] = useState<string>();

  useEffect(() => {
    const get = async () => {
      const companies = await new UserApi().getUserBlockCompanies();
      if(companies.data.companies) setBlockList(companies.data.companies);

      const user = await new UserApi().getUser();
      setUser(user?.data);
      const userId = user?.data?.user_info?.user_id;
      if(userId){
        const portfolio = await new PortfolioApi().getPortfolioEdit(userId);
        switch (portfolio.data.status) {
          case 'public':
            setPortfolioStatus('パスワードなしで公開');
            break;
          case 'password':
            setPortfolioStatus('パスワードを設定して公開');
            break;
          case 'closed':
            setPortfolioStatus('公開しない');
            break;
        }
        if(!portfolio.data.publish) setPortfolioStatus('ポートフォリオWebページはまだつくられていません');
      }
    }
    get();
  }, []);

  // フォームの値に変更があったら進行度は計

  return (
    <Wrapper
      header={
        <HeaderTitle title={'各種設定'}>
          <></>
        </HeaderTitle>
      }
    >
      <>
        <div className="pt-48 pb-100 mbx-utils-stack-v--48 sm:pt-24 sm:mb-188">
          <div className="condition-selection w-624 m-auto sm:w-full">
            <SectionPortfolio
              status={portfolioStatus}
            />
            <SectionOffer
              directStatus={user?.user_info?.use_direct}
              blockCompanies={blockList}
            />
          </div>
        </div>
      </>
    </Wrapper>
  );
};
