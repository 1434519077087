import cn from 'classnames';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { Offer } from '@/utils/api-client';
import { convertDateFormat } from '@/utils/string';

import { AdminOffersModalMessageFromCompanyComponent } from '../Offers/ModalMessageFromCompany';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  offer: Offer;
};

export const UserList = (props: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const history = useHistory();
  const params: { company_id: string } = useParams();

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const isAction = useMemo(() => {
    return props.offer.status !== 1;
  }, [props.offer.status]);

  /**
   * コメントかポジション指定があるかどうか
   */
  const isCommentPosition = useMemo(() => {
    let isPosition = false;
    // positionが空のオブジェクトかどうか
    if (props.offer.position) {
      isPosition = Object.keys(props.offer.position).length !== 0;
    }
    return props.offer.comment || isPosition;
  }, [props.offer.comment, props.offer.position]);

  const style = useMemo(() => {
    const BLUE = ['bg-blue-100', 'border-gray-200'];
    const GRAY = ['bg-gray-100', 'border-gray-300'];
    const WHITE = ['bg-white', 'border-gray-300'];
    if (props.offer.mw_status === 3) return GRAY;
    if (!isAction) return BLUE;
    if (props.offer.status === 0 || props.offer.status === 4 || props.offer.status === 5) {
      return GRAY;
    }
    return WHITE;
  }, [props.offer]);

  const format = useMemo(() => {
    const created_at = convertDateFormat(props.offer.created_at);
    const rate = DIRECT_FORM_OPTIONS.offer_mw_status.find(
      (r) => r.value === props.offer.rate?.toString()
    )?.children;
    const job = FORM_OPTIONS.experienced_job.find(
      (j) => j.value === props.offer.mc_experienced_job?.toString()
    )?.children;
    return { created_at, rate, job };
  }, [props.offer]);

  const status = useMemo(() => {
    if (props.offer.status === 0) return '回答期限終了';
    if (props.offer.status === 1) return 'ピックアップ';
    if (props.offer.status === 2 || props.offer.status === 7 || props.offer.status === 11)
      return 'マイナビワークスに相談中';
    if (props.offer.status === 3) return '一次面接オファー中';
    if (props.offer.status === 8) return 'カジュアル面談オファー中';
    if (props.offer.status === 4 || props.offer.status === 5 || props.offer.status === 10)
      return '見送り';
    if (props.offer.status === 6) return '一次面接オファー承諾';
    if (props.offer.status === 9) return 'カジュアル面談オファー承諾';
  }, [props.offer]);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onClickPage = () => {
    history.push({
      pathname: `/admin/users/${props.offer.matchbox_id}`,
      state: { referrer: 'candidates', company_id: params.company_id },
    });
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className={cn(style, 'border-b')}>
      <div className="flex items-center pl-16 h-64 text-12_12 relative">
        <div className="flex items-center w-84">
          {!isAction && <p className="w-8 h-8 mr-8 bg-red-700 rounded-full"></p>}
          <p>{format.created_at}</p>
        </div>
        <div className="w-74 ml-16">
          <p className="text-blue-700 cursor-pointer" onClick={onClickPage}>
            {props.offer.matchbox_id}
          </p>
        </div>
        <div className="ml-16 overflow-hidden w-127">
          <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">{format.job}</p>
        </div>
        <div className="w-100 ml-16">
          <p>{props.offer.position?.name}</p>
        </div>
        <div className="w-88 ml-33">
          <p className="leading-5">{status}</p>
        </div>
        <div className="ml-16 text-center w-40">
          <p>{format.rate}</p>
        </div>
        <div className="w-60 ml-16 text-center">
          <p>{props.offer.reactions?.length}件</p>
        </div>
        <div className="w-72 ml-36 text-center">
          {isAction ? <p>済</p> : <p className="text-red-700 font-bold">未アクション</p>}
        </div>

        <div className="w-24 h-24 ml-28 ">
          {isCommentPosition && (
            <AdminOffersModalMessageFromCompanyComponent
              comment={props.offer.comment}
              position={props.offer.position}
            ></AdminOffersModalMessageFromCompanyComponent>
          )}
        </div>
        <div className="w-66 ml-28">
          {props.offer.id && (
            <BaseButton
              theme="secondary"
              size="ss"
              className="w-full"
              href={decodeURI(`/admin/companies/${params.company_id}/candidates/${props.offer.id}`)}
            >
              詳細
            </BaseButton>
          )}
        </div>
      </div>
    </section>
  );
};
