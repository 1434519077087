export const pageList = [
  {
    category: 'TOP',
    title: 'TOP',
    path: '/',
    virtualPage: '',
  },
  {
    category: 'TOP',
    title: '重要なお知らせ（1）',
    path: '/',
    virtualPage: 'notification_step_1',
  },
  {
    category: 'TOP',
    title: '重要なお知らせ（2）',
    path: '/',
    virtualPage: 'notification_step_2',
  },
  {
    category: 'TOP',
    title: '重要なお知らせ（3）',
    path: '/',
    virtualPage: 'notification_step_3',
  },
  {
    category: 'TOP',
    title: 'ポートフォリオって必要？',
    path: '/',
    virtualPage: 'necessity',
  },
  {
    category: 'TOP',
    title: 'なぜMATCHBOXは転職に強い？',
    path: '/',
    virtualPage: 'why',
  },
  {
    category: 'TOP',
    title: 'MATCHBOXでできることは？',
    path: '/',
    virtualPage: 'potential',
  },
  {
    category: 'TOP',
    title: 'オファー機能とは',
    path: '/',
    virtualPage: 'offer',
  },
  {
    category: 'TOP',
    title: '多彩な取引企業',
    path: '/',
    virtualPage: 'companies',
  },
  {
    category: 'アカウント登録',
    title: 'アカウント登録',
    path: '/entry',
    virtualPage: 'step_1',
  },
  {
    category: 'アカウント登録',
    title: '仮登録ありがとうございます',
    path: '/entry',
    virtualPage: 'step_2',
  },
  {
    category: 'アカウント登録',
    title: 'アカウント登録（パスワードを設定の上、会員登録を完了してください。）',
    path: /^\/entry\?[^/]+$/,
    virtualPage: 'step_3',
  },
  {
    category: 'その他',
    title: '利用規約',
    path: '/terms_of_use',
    virtualPage: '',
  },
  { category: 'その他', title: 'よくあるご質問', path: '/faq', virtualPage: '' },
  {
    category: 'ログイン',
    title: 'ログイン',
    path: '/login',
    virtualPage: '',
  },
  {
    category: 'ログイン',
    title: 'パスワードを忘れた方',
    path: '/login/reminder',
    virtualPage: 'step_1',
  },
  {
    category: 'ログイン',
    title: 'パスワード再設定（パスワード再設定用のリンクを送信しました。）',
    path: '/login/reminder',
    virtualPage: 'step_2',
  },
  {
    category: 'ログイン',
    title: 'パスワード再設定（新しいパスワードを入力して、パスワードを再設定してください。）',
    path: /^\/login\/reminder\?[^/]+$/,
    virtualPage: 'step_3',
  },
  {
    category: 'オンボーディング',
    title: 'オンボーディング1（MATCHBOXヘようこそ）',
    virtualPage: 'on-boarding_step_1',
  },
  {
    category: 'オンボーディング',
    title: 'オンボーディング2（転職活動に必要な書類がすべて揃う）',
    virtualPage: 'on-boarding_step_2',
  },
  {
    category: 'オンボーディング',
    title: 'オンボーディング3（企業からオファーをもらおう）',
    virtualPage: 'on-boarding_step_3',
  },
  {
    category: 'オンボーディング',
    title: '作品を追加しよう（MATCHBOXをはじめる）',
    virtualPage: 'on-boarding_step_4',
  },
  {
    category: 'システム出力',
    title: 'Error Page',
    path: '',
    virtualPage: '',
  },
  {
    category: 'マイページ',
    title: 'マイページ',
    path: '/mypage',
    virtualPage: '',
  },
  {
    category: 'マイページ',
    title: '各種設定',
    path: '/mypage/setting',
    virtualPage: '',
  },
  {
    category: 'マイページ',
    title: 'アカウント（メールアドレス・パスワード）編集',
    path: '/mypage/account',
    virtualPage: 'mail-pw_step_1',
  },
  {
    category: 'マイページ',
    title: 'アカウント管理（メール アドレスの変更）',
    path: '/mypage/account',
    virtualPage: 'mail_step_2',
  },
  {
    category: 'マイページ',
    title: 'アカウント（メールアドレス・パスワード）編集 : メールアドレス変更完了',
    path: /^\/mypage\/account\?[^/]+$/,
    virtualPage: 'mail_step_3',
  },
  {
    category: 'マイページ',
    title: 'プロフィール編集',
    path: '/mypage/profile',
    virtualPage: '',
  },
  {
    category: 'マイページ',
    title: '希望条件編集',
    path: '/mypage/condition',
    virtualPage: '',
  },
  {
    category: 'マイページ',
    title: 'オファー機能とは',
    path: '{モーダル表示箇所のpath}',
    virtualPage: 'about-matchbox-direct',
  },
  {
    category: 'マイページ',
    title: 'オファー機能／申請ページ1',
    path: '/mypage/direct',
    virtualPage: 'step_1',
  },
  {
    category: 'マイページ',
    title: 'オファー機能／申請ページ2',
    path: '/mypage/direct',
    virtualPage: 'step_2',
  },
  {
    category: 'マイページ',
    title: 'オファー機能／登録完了ページ',
    path: '/mypage/direct',
    virtualPage: 'step_3',
  },
  {
    category: 'マイページ',
    title: 'オファー機能／オファー状況確認',
    path: '/mypage/direct_status',
    virtualPage: '',
  },
  {
    category: 'マイページ',
    title: 'ブロックする企業設定（ブロックした企業一覧）',
    path: '/mypage/direct_status',
    virtualPage: 'block-list',
  },
  {
    category: 'マイページ',
    title: 'オファー機能／停止',
    path: '/mypage/direct_status',
    virtualPage: 'stop-matchbox-direct',
  },
  {
    category: 'マイページ',
    title: 'ブロックする企業検索／検索',
    path: '/mypage/direct',
    virtualPage: 'search-for-companies-to-block_step_1',
  },
  {
    category: 'マイページ',
    title: 'ブロックする企業検索／検索結果一覧',
    path: '/mypage/direct',
    virtualPage: 'search-for-companies-to-block_step_2',
  },
  {
    category: 'マイページ',
    title: 'ブロックする企業検索／ブロック完了',
    path: '/mypage/direct',
    virtualPage: 'search-for-companies-to-block_step_3',
  },
  {
    category: 'ポートフォリオ／編集',
    title: 'TOPページ／編集',
    path: '/mypage/portfolio/edit',
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／編集',
    title: 'ポートフォリオ設定',
    path: '/mypage/portfolio/setting',
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／公開',
    title: 'TOPページ／公開',
    path: /^\/portfolio\/[^/]+(\/|)$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／公開',
    title: 'TOPページ／パスワード入力',
    path: /^\/portfolio\/[^/]+(\/|)$/,
    virtualPage: 'input-pw',
  },
  {
    category: 'ポートフォリオ／閲覧',
    title: 'TOPページ／プレビュー',
    path: /^\/mypage\/portfolio\/[^/]+$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／公開',
    title: '作品詳細ページ／公開',
    path: /^\/portfolio\/[^/]+\/\d+(\/|)$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／公開',
    title: '作品詳細ページ／パスワード入力',
    path: /^\/portfolio\/[^/]+\/\d+(\/|)$/,
    virtualPage: 'input-pw',
  },
  {
    category: 'ポートフォリオ／閲覧',
    title: '作品詳細ページ／プレビュー',
    path: /^\/mypage\/portfolio\/[^/]+\/\d+(\/|)$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／公開',
    title: 'プロフィール／公開',
    path: /^\/portfolio\/[^/]+\/profile(\/|)$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／公開',
    title: 'プロフィール／パスワード入力',
    path: /^\/portfolio\/[^/]+\/profile(\/|)$/,
    virtualPage: 'input-pw',
  },
  {
    category: 'ポートフォリオ／閲覧',
    title: 'プロフィール／プレビュー',
    path: /^\/mypage\/portfolio\/[^/]+\/profile$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／閲覧',
    title: 'ポートフォリオの公開設定',
    virtualPage: 'publish-portfolio-setting_step_1',
  },
  {
    category: 'ポートフォリオ／閲覧',
    title: '公開されました！',
    virtualPage: 'publish-portfolio-setting_step_2',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '作品詳細編集',
    path: /^\/mypage\/portfolio\/[^/]+\/\d+\/edit$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／編集',
    title: 'プロフィール編集',
    path: /^\/mypage\/portfolio\/[^/]+\/profile\/edit$/,
    virtualPage: '',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '画像追加フロー（作品タイプを選択）',
    virtualPage: 'add-a-work',
  },
  {
    category: 'ポートフォリオ／編集',
    title: 'Webページを追加',
    virtualPage: 'add-a-work_wp',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '動画を追加',
    virtualPage: 'add-a-work_video',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '音楽を追加',
    virtualPage: 'add-a-work_music',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '資料を追加',
    virtualPage: 'add-a-work_doc',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '3Dモデルを追加',
    virtualPage: 'add-a-work_3d',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '画像アップロード',
    virtualPage: 'upload-images',
  },
  {
    category: '履歴書 ',
    title: '履歴書編集',
    path: '/mypage/resume/edit',
    virtualPage: '',
  },
  {
    category: '履歴書',
    title: '画像アップロード',
    path: '/mypage/resume/edit',
    virtualPage: 'upload-images',
  },
  {
    category: 'ポートフォリオ／編集',
    title: '作品を削除しますか？',
    path: '/mypage/portfolio/edit',
    virtualPage: 'delete-a-work',
  },
  {
    category: '履歴書 ',
    title: '履歴書確認',
    path: '/mypage/resume',
    virtualPage: '',
  },
  {
    category: '職務経歴書',
    title: '職務経歴書確認',
    path: '/mypage/cv',
    virtualPage: '',
  },
  {
    category: '職務経歴書',
    title: '職務経歴書編集',
    path: '/mypage/cv/edit',
    virtualPage: '',
  },
];
