import React from 'react';

import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormCombinationsFromToDropdown } from '@/componentsDirect/Parts/Form/Combinations/FromToDropdown';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';

/**
 * 検索条件フォーム 就業状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionUpdates = (): React.ReactElement => {
  return (
    <UserCondition title="更新情報">
      <>
        <div className="flex">
          <FormCombinationsFromToDropdown
            name="portfolio_item_count"
            label="ポートフォリオ作品数"
            placeholder="選択してください"
            selectList={DIRECT_FORM_OPTIONS.portfolio_item_count}
            className="ml-24"
            fieldWidthClass="w-176"
          />

          <FormLayoutFieldset className="ml-24 w-174">
            <FormLabel label="ポートフォリオ更新日" type="small"></FormLabel>
            <FormContainerDropdown
              name="last_update_portfolio"
              placeholder="選択してください"
              selectList={DIRECT_FORM_OPTIONS.last_update}
              className="mkt_non_mask_items"
            />
          </FormLayoutFieldset>

          <FormLayoutFieldset className="ml-24 w-174">
            <FormLabel label="職務経歴書更新日" type="small"></FormLabel>
            <FormContainerDropdown
              name="last_update_career_history"
              placeholder="選択してください"
              selectList={DIRECT_FORM_OPTIONS.last_update}
              className="mkt_non_mask_items"
            />
          </FormLayoutFieldset>
        </div>
      </>
    </UserCondition>
  );
};
