import { AxiosError } from 'axios';
import cn from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { ContentsBlank } from '@/components/common/ContentsBlank';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import BlockSection from '@/components/common/Direct/BlockSection';
import InvitationModal from '@/components/common/Direct/InvitationModal';
import OfferList from '@/components/common/Direct/OfferList';
import StopDirectModal from '@/components/common/Direct/StopDirectModal';
import Pager from '@/components/common/Navigation/Page/Pager';
import * as Page from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import {
  notificationError,
  setDataLayer,
  setDataLayerChangedBy,
  toggleLoading,
  userInfoForceUpdate,
} from '@/redux/index';
import { State } from '@/redux/state';
import { Offer, ResponseError, UserApi, UserInfo } from '@/utils/api-client';

type Param = {
  id: string;
  status: number;
  is_read: boolean;
  mw_status?: number;
};

function DirectStatus(): React.ReactElement {
  const [invitation, setInvitation] = useState<boolean>(false);
  const [blockCompany, setBlockCompany] = useState<boolean>(false);
  const [stopDirect, setStopDirect] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(0);
  const [offers, setOffers] = useState<Offer[]>();
  const mq = useMBXMediaQuery();
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { push } = useContext(DataLayerContext);
  useEffect(() => {
    if (user?.use_direct) {
      getOffer();
    } else {
      history.push('/mypage/direct');
    }
  }, [user]);

  const offset = useMemo(() => {
    if (page === 0) return 0;
    return page * 10;
  }, [page]);

  const getOffer = async () => {
    try {
      const res = await new UserApi().getOffers('10', `${offset}`, '', '');
      if (res.data.offers) {
        setOffers(res.data.offers);
        onIsRead(res.data.offers);
      }
      if (res.data.total) {
        setTotal(res.data.total);
        setPageLength(Math.ceil(res.data.total / 10));
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      //
    }
  };

  const onIsRead = (offers: Offer[]) => {
    const noReadOffers = offers.filter((o) => {
      return !o.is_read;
    });
    noReadOffers.forEach(async (offer) => {
      if (offer.id) {
        const param = {
          id: offer.id.toString(),
          status: offer.status,
          is_read: true,
        };
        try {
          await new UserApi().patchOffer(param);
        } catch (error) {
          const e = error as AxiosError<ResponseError>;
          if (e.response) dispatch(notificationError(e.response.data.error_message));
        } finally {
          //
        }
      }
    });
  };

  useEffect(() => {
    if (user?.use_direct) getOffer();
  }, [page]);

  const statusUpdate = async (id: number, status: number) => {
    const param: Param = {
      id: id.toString(),
      status: status,
      is_read: true,
    };
    if (status === 5 || status === 10) param.mw_status = 3;
    const statusTitle = (status: number): string => {
      switch (status) {
        case 5:
          return '一次面接オファー見送り';
        case 6:
          return '一次面接オファーに応諾';
        case 7:
          return 'マイナビワークスに一次面接オファー相談';
        case 9:
          return 'カジュアル面談オファーに応諾';
        case 10:
          return 'カジュアル面談オファー見送り';
        case 11:
          return 'マイナビワークスにカジュアル面談オファー相談';
      }
      return '';
    };
    dispatch(toggleLoading(true));
    push({
      event: 'reaction',
      actionType: 'reaction',
      actionName: statusTitle(status),
    });
    try {
      await new UserApi().patchOffer(param);
      getOffer();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const onDirectStop = async () => {
    if (!user) return;
    // ユーザー情報編集APIの必須項目対応で不要な情報もリクエスト
    // 便宜上送ってるだけなので本来はuse_directだけ変更するAPIを別途用意した方がいいかも
    const data = {
      name: user.name,
      furigana: user.furigana,
      birth_year: user.birth_year,
      birth_month: user.birth_month,
      birth_day: user.birth_day,
      use_direct: false,
      matchbox_id: user.matchbox_id as string,
    };
    dispatch(toggleLoading(true));
    try {
      const result = await new UserApi().patchUser(data);
      if (result.data.user_info) dispatch(userInfoForceUpdate(result.data.user_info));
      dispatch(setDataLayerChangedBy('matchbox_offer_stop'));
      dispatch(setDataLayer({ mbOfferStatus: 'off' }));
      push({
        event: 'mbOfferStop',
        actionType: 'matchbox_offer_stop',
        actionName: 'matchbox_offer_stop',
      });
      history.push('/mypage');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <section className="direct-status">
      <Page.Wrapper
        disableDataLayerPush
        header={
          <HeaderTitle title="オファー機能">
            <div className="flex justify-end">
              {mq.sm ? (
                <ButtonIconButton
                  size="default"
                  iconName="Help"
                  type="blue"
                  onClick={() => setInvitation(true)}
                />
              ) : (
                <BaseButton
                  size="s"
                  theme="link"
                  iconName="Help"
                  onClick={() => setInvitation(true)}
                >
                  オファー機能とは
                </BaseButton>
              )}
            </div>
          </HeaderTitle>
        }
      >
        <>
          <StopDirectModal isOpen={stopDirect} isClose={setStopDirect} onStop={onDirectStop} />
          <InvitationModal isOpen={invitation} isClose={setInvitation} />
          {!blockCompany && (
            <DataLayerPushContainer
              data={{
                event: 'pageView',
                actionType: 'page_view',
                actionName: 'page_view',
              }}
            >
              {total === 0 ? (
                <ContentsBlank
                  className="mt-50 pb-50"
                  title="オファーはまだありません。"
                  text={
                    <span>
                      オファーがあれば、MATCHBOXに
                      <br className="hidden sm:block" />
                      ご登録のメールアドレスにもお知らせします。
                      <br />
                      オファーの回答期限は2週間です。
                      <br className="hidden sm:block" />
                      メールのチェックをお忘れなく。
                    </span>
                  }
                />
              ) : (
                <>
                  <p className="mbx-typography--body_1 text-center mt-48 sm:mt-24">
                    気になるオファーがあれば、応諾するボタンを押してください。マイナビクリエイターより詳細をご案内します。
                    <br />
                    2週間を過ぎたオファーには回答ができなくなりますので、ご注意ください。
                  </p>
                  <section>
                    <p className="mbx-typography--body_2 mt-42 sm:mt-34">
                      {page === 0 ? page + 1 : page + '1'}〜
                      {page === pageLength - 1 ? total : page + 1 + '0'} / {total}件
                    </p>
                    <div className="mt-10">
                      {offers && (
                        <div>
                          {offers.map((item: Offer, index: number) => {
                            return (
                              <div
                                className={cn(
                                  'border-b',
                                  'border-gray-300',
                                  'sm:p-16',
                                  'sm:-mx-24',
                                  index === 0 && 'border-t'
                                )}
                                key={index}
                              >
                                <OfferList offer={item} statusUpdate={statusUpdate} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="mt-12">
                      {pageLength && (
                        <Pager page={page} pageLength={pageLength} setPage={setPage} />
                      )}
                    </div>
                  </section>
                </>
              )}

              <ul className="flex justify-end mt-40 mb-96 sm:flex-col sm:w-248 sm:text-right sm:mb-212">
                <li
                  className="text-12_14 text-blue-700 font-bold mr-40 cursor-pointer sm:mr-0"
                  onClick={() => setBlockCompany(true)}
                >
                  ブロックする企業の設定
                </li>
                <li
                  className="text-12_14 text-blue-700 font-bold cursor-pointer sm:mt-34"
                  onClick={() => setStopDirect(true)}
                >
                  オファー機能を停止する
                </li>
              </ul>
            </DataLayerPushContainer>
          )}
          {blockCompany && (
            <DataLayerPushContainer
              data={{
                event: 'pageView',
                actionType: 'page_view',
                actionName: 'page_view',
                virtualPageName: 'block-list',
              }}
            >
              <section className="mb-136">
                <div className="h-64 flex items-center">
                  <BaseButton
                    iconName="Arrow_Left"
                    size="s"
                    theme="link"
                    onClick={() => setBlockCompany(false)}
                  >
                    戻る
                  </BaseButton>
                </div>
                <p className="mbx-typography--body_1 text-center">
                  あなたのポートフォリオを表示させない企業を設定できます。
                  <br />
                  現在の勤務先や関連会社などを検索して設定してください。
                </p>
                <BlockSection />
              </section>
            </DataLayerPushContainer>
          )}
        </>
      </Page.Wrapper>
    </section>
  );
}

export default DirectStatus;
