import React from 'react';

import { FormCombinationsFromToDatepicker } from '@/componentsDirect/Parts/Form/Combinations/FromToDatepicker';
import { FormCombinationsFromToDropdown } from '@/componentsDirect/Parts/Form/Combinations/FromToDropdown';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';

/**
 * 検索条件フォーム 就業状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const AdminSearchFormSectionUpdates = (): React.ReactElement => {
  return (
    <UserCondition title="更新情報">
      <>
        <div className="flex mb-22">
          <FormCombinationsFromToDropdown
            name="portfolio_item_count"
            label="ポートフォリオ登録作品数"
            placeholder="選択してください"
            selectList={DIRECT_FORM_OPTIONS.direct_portfolio_item_count}
            className="ml-24"
            fieldWidthClass="w-176"
          />
        </div>

        <div className="flex mb-22">
          <FormCombinationsFromToDropdown
            name="direct_portfolio_item_count"
            label="企業向けポートフォリオ公開作品数"
            placeholder="選択してください"
            selectList={DIRECT_FORM_OPTIONS.portfolio_item_count}
            className="ml-24"
            fieldWidthClass="w-176"
          />

          <FormCombinationsFromToDropdown
            name="published_portfolio_item_count"
            label="一般向けポートフォリオ公開作品数"
            placeholder="選択してください"
            selectList={DIRECT_FORM_OPTIONS.portfolio_item_count}
            className="ml-24"
            fieldWidthClass="w-176"
          />
        </div>

        <div className="flex mb-22">
          <FormLayoutFieldset className="pl-24 w-174">
            <FormLabel label="ポートフォリオ公開状態" type="small"></FormLabel>
            <FormContainerDropdown
              name="portfolio_state"
              placeholder="選択してください"
              selectList={FORM_OPTIONS.portfolio_status}
            ></FormContainerDropdown>
          </FormLayoutFieldset>

          <FormCombinationsFromToDatepicker
            className="pl-24"
            name="last_update_portfolio"
            label="ポートフォリオ更新日"
            fieldWidthClass="w-126"
            placeholder="yyyy/mm/dd"
            minYesr={2018}
          />

          <FormCombinationsFromToDatepicker
            className="pl-24"
            name="last_update_career_history"
            label="職務経歴書更新日"
            fieldWidthClass="w-126"
            placeholder="yyyy/mm/dd"
            minYesr={2018}
          />
        </div>

        <div className="flex">
          <FormCombinationsFromToDatepicker
            className="pl-24"
            name="last_update_resume"
            label="履歴書更新日"
            fieldWidthClass="w-126"
            placeholder="yyyy/mm/dd"
            minYesr={2018}
          />
        </div>
      </>
    </UserCondition>
  );
};
