import React from 'react';
import cn from 'classnames';

type Props = {
  title: string;
  children: React.ReactElement;
  className?: string;
  titleWidth?: number;
};

export const DescriptionWrapper = (props: Props): React.ReactElement => {
  return (
    <section className={cn('flex', 'border-b', 'border-gray-300', props.className)}>
      <div
        className={cn(
          'p-24',
          'bg-gray-100',
          'flex-shrink-0',
          props.titleWidth ? `w-${props.titleWidth}` : 'w-144'
        )}
      >
        <p className="mbx-typography--subhead_2 text-blue-700">{props.title}</p>
      </div>
      <div className="w-full p-24">{props.children}</div>
    </section>
  );
};
