import '@/styles/common/Navigation/user-menu.scss';

import cn from 'classnames';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { MenuField } from '@/components/common/Header';
import { UserItem } from '@/components/common/Navigation/User/Item';
import { UserProfile } from '@/components/common/Navigation/User/Profile';
import InvitationModal from '@/components/common/Direct/InvitationModal';
import {
  auth,
  isLoggedOut,
  setPathAfterLogin,
  setPathAfterLogout,
  toggleLoading,
} from '@/redux/index';
import { State } from '@/redux/state';
import { AuthApi, MyNaviApi } from '@/utils/api-client';
import { delay } from '@/utils/delay';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type TProps = {
  menu?: MenuField[];
  sp?: boolean;
  open?: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = {
  size: 'm' | 's' | 'ss' | '40' | 'm-for-sp';
  theme: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'link' | 'link_secondary';
  href?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const UserMenu = ({ menu, sp, open, setToggle }: TProps): React.ReactElement => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: State) => state.isAuth);
  const user = useSelector((state: State) => state.user);
  const [changeDate, setChangeDate] = useState<0 | 1 | 2 | 3 | 4 | 5>(5);
  const [directModal, setDirectModal] = useState<boolean>(false);
  const { push } = useContext(DataLayerContext);
  const history = useHistory();
  const mq = useMBXMediaQuery();

  const menuList = useMemo(() => {
    if(menu) return (mq.lg) ? menu.filter((item) => item.url !== '/mypage') : menu;
  }, [mq]);

  const buttonProps: Props = useMemo(() => {
    const props = {
      size: "m",
      theme: "primary",
      className: "mynavi",
    } as Props
    (user?.use_direct) ? props.href = "/mypage/direct_status" : props.onClick = () => setDirectModal(true);
    return props;
  }, [user?.use_direct]);

  const logout = async () => {
    history.push(location.pathname);

    dispatch(toggleLoading(true));
    dispatch(setPathAfterLogout('/'));
    const result = await new AuthApi().getAuthLogout();
    if (result.status === 200) {
      push({
        event: 'logout',
        actionType: 'logout',
        actionName: 'logout',
      });
      dispatch(isLoggedOut());
      dispatch(auth(false));
      setToggle(false);
      dispatch(toggleLoading(false));

      // PrivateRouteの処理がこの後にくるのでdelayして処理が終わった段階で未ログイン時に遷移する先をクリアする
      delay(200).then(() => {
        dispatch(setPathAfterLogin(undefined));
      });
    }
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      const m = await new MyNaviApi().getMynaviCreator();
      const date = m.data.mynavi_creator.mc_preferred_change_date;
      if (date) {
        setChangeDate((date - 1) as 0 | 1 | 2 | 3 | 4 | 5);
      }
    })();
  }, [user, open]);

  return (
    <div className={cn('mbx-user-menu')}>
      {isAuth && (
        <section>
          {user && (
            <UserProfile
              {...{
                preferredChangeDate: changeDate,
                direct: user.use_direct ? '登録中' : '停止中',
                icon: user.icon ? user.icon : '/images/circle-placeholder.png',
                name: user.name ? user?.name : '',
                sp,
              }}
            />
          )}
        </section>
      )}
      {isAuth && user && (
        <>
          <hr className="border-gray-300" />
          <section>
            <BaseButton
              {...buttonProps}
            >
                {user.use_direct ? (
                  "オファー履歴をチェック"
                ) : (
                  "オファー機能に登録する"
                )}
            </BaseButton>
          </section>
        </>
      )}
      {menuList && (
        <>
          <hr className="border-gray-300" />
          {menuList?.map((m: MenuField, index: number) => (
            <UserItem key={index} to={m.url} bold target={m.target}>
              {m.title}
            </UserItem>
          ))}
        </>
      )}
      <hr className="border-gray-300" />
      {isAuth && (
        <>
          <UserItem to="/mypage/profile">基本情報管理</UserItem>
          <UserItem to="/mypage/condition">経験・希望の設定</UserItem>
          <UserItem to="/mypage/account">アカウント管理</UserItem>
          <UserItem to="/mypage/setting">各種設定</UserItem>
          <UserItem onClick={logout}>ログアウト</UserItem>
          {user?.cp2_status === 1 && (
            <>
              <hr className="border-gray-300" />
              <section>
                <BaseButton
                  size="m"
                  theme="secondary"
                  className="mynavi"
                  href="https://mynavi-creator.candidatemypage.jp/mynavi-creator/login/"
                  iconName="External_link"
                  iconLayout="right"
                  image="/images/mc-logo.png"
                >
                  選考状況を確認する
                </BaseButton>
              </section>
            </>
          )}
        </>
      )}
      <InvitationModal isOpen={directModal} isClose={setDirectModal} />
    </div>
  );
};
