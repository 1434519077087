export type TFormOption = {
  value: string;
  children: string;
};

const prefecture: Array<TFormOption> = [
  { value: '1', children: '北海道' },
  { value: '2', children: '青森県' },
  { value: '3', children: '岩手県' },
  { value: '4', children: '宮城県' },
  { value: '5', children: '秋田県' },
  { value: '6', children: '山形県' },
  { value: '7', children: '福島県' },
  { value: '8', children: '茨城県' },
  { value: '9', children: '栃木県' },
  { value: '10', children: '群馬県' },
  { value: '11', children: '埼玉県' },
  { value: '12', children: '千葉県' },
  { value: '13', children: '東京都' },
  { value: '14', children: '神奈川県' },
  { value: '15', children: '新潟県' },
  { value: '16', children: '富山県' },
  { value: '17', children: '石川県' },
  { value: '18', children: '福井県' },
  { value: '19', children: '山梨県' },
  { value: '20', children: '長野県' },
  { value: '21', children: '岐阜県' },
  { value: '22', children: '静岡県' },
  { value: '23', children: '愛知県' },
  { value: '24', children: '三重県' },
  { value: '25', children: '滋賀県' },
  { value: '26', children: '京都府' },
  { value: '27', children: '大阪府' },
  { value: '28', children: '兵庫県' },
  { value: '29', children: '奈良県' },
  { value: '30', children: '和歌山県' },
  { value: '31', children: '鳥取県' },
  { value: '32', children: '島根県' },
  { value: '33', children: '岡山県' },
  { value: '34', children: '広島県' },
  { value: '35', children: '山口県' },
  { value: '36', children: '徳島県' },
  { value: '37', children: '香川県' },
  { value: '38', children: '愛媛県' },
  { value: '39', children: '高知県' },
  { value: '40', children: '福岡県' },
  { value: '41', children: '佐賀県' },
  { value: '42', children: '長崎県' },
  { value: '43', children: '熊本県' },
  { value: '44', children: '大分県' },
  { value: '45', children: '宮崎県' },
  { value: '46', children: '鹿児島県' },
  { value: '47', children: '沖縄県' },
  { value: '48', children: '海外' },
];

const experienced_job: Array<TFormOption> = [
  { value: '1', children: 'Webプロデューサー' },
  { value: '2', children: 'Webディレクター' },
  { value: '6', children: 'Webマーケター・Webアナリスト' },
  { value: '3', children: 'Webデザイナー' },
  { value: '4', children: 'UI/UXデザイナー（Web系）' },
  { value: '5', children: 'フロントエンドエンジニア' },
  { value: '7', children: 'Webプログラマー' },
  { value: '8', children: 'Webコンテンツ企画・編集・ライター' },
  { value: '9', children: 'ECサイト運営' },
  { value: '10', children: '動画・映像クリエイター' },
  { value: '18', children: 'その他Web系職種' },
  { value: '14', children: 'ゲームプロデューサー' },
  { value: '30', children: 'ゲームディレクター' },
  { value: '15', children: 'ゲームプランナー' },
  { value: '16', children: 'UI/UXデザイナー（ゲーム系）' },
  { value: '31', children: '2Dデザイナー（ゲーム系）' },
  { value: '32', children: '3Dデザイナー' },
  { value: '17', children: 'ゲームプログラマー' },
  { value: '28', children: 'シナリオライター' },
  { value: '33', children: 'ローカライズ' },
  { value: '34', children: 'サウンドクリエイター' },
  { value: '35', children: 'デバッガー' },
  { value: '36', children: 'その他ゲーム系職種' },
  { value: '50', children: 'プロジェクトマネージャー（Web系）' },
  { value: '51', children: 'プロジェクトマネージャー（Web系以外）' },
  { value: '52', children: 'システムエンジニア（Web系）' },
  { value: '53', children: 'システムエンジニア（Web系以外）' },
  { value: '54', children: 'ネットワーク設計・構築' },
  { value: '55', children: 'サーバー設計・構築' },
  { value: '56', children: '運用保守・監視' },
  { value: '57', children: 'テクニカルサポート・ヘルプデスク' },
  { value: '58', children: 'その他IT・システム系職種' },
  { value: '13', children: 'アートディレクター' },
  { value: '12', children: 'グラフィックデザイナー' },
  { value: '70', children: 'DTPオペレーター' },
  { value: '71', children: 'パッケージ・SPデザイナー' },
  { value: '72', children: 'プロダクトデザイナー' },
  { value: '11', children: '編集・ライター・記者' },
  { value: '73', children: 'その他広告系職種' },
  { value: '82', children: '経営企画' },
  { value: '85', children: '事業戦略・マーケティング' },
  { value: '83', children: '広報' },
  { value: '81', children: 'コンサルタント' },
  { value: '80', children: '営業' },
  { value: '84', children: '販売' },
  { value: '86', children: '人事' },
  { value: '88', children: '財務' },
  { value: '89', children: '経理' },
  { value: '90', children: '法務' },
  { value: '87', children: '総務' },
  { value: '99', children: 'その他職種' },
];

const preferred_job_type: Array<TFormOption> = [
  ...experienced_job,
  { value: '100', children: '在学中のため未経験' },
];

const yearly_income: Array<TFormOption> = [
  { value: '1', children: '199万円以下' },
  { value: '2', children: '200万円' },
  { value: '3', children: '250万円' },
  { value: '4', children: '300万円' },
  { value: '5', children: '350万円' },
  { value: '6', children: '400万円' },
  { value: '7', children: '450万円' },
  { value: '8', children: '500万円' },
  { value: '9', children: '550万円' },
  { value: '10', children: '600万円' },
  { value: '11', children: '650万円' },
  { value: '12', children: '700万円' },
  { value: '13', children: '750万円' },
  { value: '14', children: '800万円' },
  { value: '15', children: '850万円' },
  { value: '16', children: '900万円' },
  { value: '17', children: '950万円' },
  { value: '18', children: '1000万円以上' },
];

const business_type: Array<TFormOption> = [
  { value: '1', children: '出版' },
  { value: '2', children: '編集プロダクション' },
  { value: '3', children: '広告プロダクション' },
  { value: '4', children: '広告代理店' },
  { value: '5', children: 'DTP業' },
  { value: '6', children: '印刷・印刷関連' },
  { value: '7', children: '新聞・通信社' },
  { value: '8', children: '放送' },
  { value: '9', children: 'ソフトウエア・情報処理系' },
  { value: '10', children: '通信系' },
  { value: '11', children: 'インターネット系' },
  { value: '13', children: 'メーカー系（電気・電子・機械系）' },
  { value: '14', children: '商社系（電気・電子・機械系）' },
  { value: '18', children: 'Web系制作会社' },
  { value: '23', children: 'メーカー系（医薬品・食品・その他）' },
  { value: '24', children: '商社系（総合・医薬品・その他）' },
  { value: '33', children: 'エネルギー・プラント系' },
  { value: '34', children: '建築・土木・不動産系' },
  { value: '45', children: '流通・小売系' },
  { value: '49', children: '金融系' },
  { value: '59', children: '運輸・交通系' },
  { value: '62', children: 'サービス系' },
  { value: '65', children: 'コンサルタント・シンクタンク系' },
  { value: '66', children: '人材サービス系' },
  { value: '70', children: '教育系・その他' },
  { value: '74', children: 'マイナビ・関連会社' },
  { value: '75', children: 'ゲーム系' },
];

const experienced_year: Array<TFormOption> = [
  { value: '1', children: '1年未満' },
  { value: '2', children: '1年' },
  { value: '3', children: '2年' },
  { value: '4', children: '3年' },
  { value: '5', children: '4年' },
  { value: '6', children: '5年以上' },
];

const experienced_skill: Array<TFormOption> = [
  { value: '1', children: '1年未満' },
  { value: '2', children: '1年' },
  { value: '3', children: '2年' },
  { value: '4', children: '3年以上' },
];

const companies_count: Array<TFormOption> = [
  {
    value: '1',
    children: '1社',
  },
  {
    value: '2',
    children: '2社',
  },
  {
    value: '3',
    children: '3社',
  },
  {
    value: '4',
    children: '4社',
  },
  {
    value: '5',
    children: '5社',
  },
  {
    value: '6',
    children: '6社',
  },
  {
    value: '7',
    children: '7社',
  },
  {
    value: '8',
    children: '8社',
  },
  {
    value: '9',
    children: '9社',
  },
  {
    value: '10',
    children: '10社',
  },
];

/**
 * 就業状況
 */
const situation: Array<TFormOption> = [
  {
    value: '2',
    children: '就業している',
  },
  {
    value: '1',
    children: '就業していない',
  },
];

const change_date: Array<TFormOption> = [
  { value: '1', children: 'すぐにでも' },
  { value: '2', children: '1ヶ月以内' },
  { value: '3', children: '3ヶ月以内' },
  { value: '4', children: '6ヶ月以内' },
  { value: '5', children: '1年以内' },
  { value: '6', children: '未定' },
];

const maximum_management_count: Array<TFormOption> = [
  { value: '1', children: '5人以下' },
  { value: '2', children: '6-10人' },
  { value: '3', children: '11-20人' },
  { value: '4', children: '21-50人' },
  { value: '5', children: '51-100人' },
  { value: '6', children: '101-500人' },
  { value: '7', children: '501人以上' },
];

const english_skills: Array<TFormOption> = [
  { value: '1', children: '文書・マニュアル読解' },
  { value: '2', children: 'e-mailでのやりとり' },
  { value: '3', children: '電話での会話' },
  { value: '4', children: '英語での会議' },
  { value: '5', children: '英語での商談・交渉' },
  { value: '6', children: '海外滞在' },
];

const final_education: Array<TFormOption> = [
  { value: '1', children: '高校' },
  { value: '2', children: '専門学校' },
  { value: '3', children: '高等専門学校' },
  { value: '4', children: '短期大学' },
  { value: '5', children: '大学' },
  { value: '6', children: '大学院（MBA含む）' },
  { value: '7', children: 'その他' },
];

const skill_level: Array<TFormOption> = new Array(5)
  .fill(null)
  .map((v, index) => ({ value: `${index + 1}`, children: `${index + 1}` }));
// qa_id: 'b_4',
const production_duration: Array<TFormOption> = [
  {
    value: '1',
    children: '〜1週間',
  },
  {
    value: '2',
    children: '2週間',
  },
  {
    value: '3',
    children: '3週間',
  },
  {
    value: '4',
    children: '1ヶ月',
  },
  {
    value: '5',
    children: '2ヶ月',
  },
  {
    value: '6',
    children: '3ヶ月',
  },
  {
    value: '7',
    children: '6ヶ月',
  },
  {
    value: '8',
    children: '1年',
  },
  {
    value: '9',
    children: '2年',
  },
  {
    value: '10',
    children: '3年',
  },
  {
    value: '11',
    children: '4年',
  },
  {
    value: '12',
    children: '5年',
  },
  {
    value: '13',
    children: '6年〜',
  },
];

//b_
const product_category: Array<TFormOption> = [
  {
    value: '1',
    children: 'WEBサイト',
  },
  {
    value: '2',
    children: 'インターネットサービス',
  },
  {
    value: '3',
    children: 'DTP',
  },
  {
    value: '4',
    children: '印刷',
  },
  {
    value: '5',
    children: '編集',
  },
  {
    value: '6',
    children: '広告',
  },
  {
    value: '7',
    children: 'グラフィック',
  },
  {
    value: '8',
    children: 'ゲーム',
  },
  {
    value: '9',
    children: 'アミューズメント',
  },
  {
    value: '10',
    children: 'テレビ',
  },
  {
    value: '11',
    children: 'プロダクト',
  },
  {
    value: '12',
    children: 'インテリア',
  },
  {
    value: '13',
    children: '空間',
  },
  {
    value: '14',
    children: 'ファッション',
  },
  {
    value: '99',
    children: 'その他',
  },
];

// qa_id: 'b_5',
// qa_question: 'マネジメント人数',
// v1からの値を引き継ぐ必要があるので、maxmum_management_countと区別する
const management_count: Array<TFormOption> = [
  {
    value: '1',
    children: '1人',
  },
  {
    value: '2',
    children: '2人',
  },
  {
    value: '3',
    children: '3人',
  },
  {
    value: '4',
    children: '5人以下',
  },
  {
    value: '5',
    children: '10人以下',
  },
  {
    value: '6',
    children: '10人以上',
  },
];

/**
 * ユーザー検索 ソート項目
 * {sort_by} - {sort_order} のvalueを設定
 */
const search_users_sort_base: Array<TFormOption> = [
  { value: 'registration_date-desc', children: 'アカウント開設日が近い順' },
  { value: 'last_login-desc', children: '最終ログイン日が近い順' },
  { value: 'mc_preferred_change_date-asc', children: '転職希望時期が近い順' },
  { value: 'mc_preferred_change_date-desc', children: '転職希望時期が遠い順' },
];
const search_users_sort_type: Array<TFormOption> = [
  ...search_users_sort_base,
  { value: 'portfolio_item_count-desc', children: 'ポートフォリオ作品数が多い順' },
];
const admin_search_users_sort_type: Array<TFormOption> = [
  ...search_users_sort_base,
  { value: 'portfolio_item_count_contained_invisible-desc', children: '登録作品数が多い順' },
  { value: 'portfolio_item_count-desc', children: '企業向け公開作品が多い順' },
  { value: 'published_portfolio_item_count-desc', children: '一般向け公開作品が多い順' },
];

/**
 * 性別
 */
const gender: Array<TFormOption> = [
  { value: '0', children: '未設定' },
  { value: '1', children: '女性' },
  { value: '2', children: '男性' },
];

/**
 * 雇用形態
 */
const employment_contract_type: Array<TFormOption> = [
  { value: '1', children: '正社員' },
  { value: '2', children: '契約社員' },
  { value: '3', children: '派遣社員' },
  { value: '4', children: 'アルバイト' },
  { value: '5', children: '業務委託' },
];

const employee_amount: Array<TFormOption> = [
  { value: '1', children: '10名以下' },
  { value: '2', children: '11～50名' },
  { value: '3', children: '51名～100名' },
  { value: '4', children: '101～500名' },
  { value: '5', children: '501～1,000名' },
  { value: '6', children: '1,001～5,000名' },
  { value: '7', children: '5,001名以上' },
];

/**
 * マッチ度
 */
const offer_rate: Array<TFormOption> = [
  { value: '3', children: '高' },
  { value: '2', children: '中' },
  { value: '1', children: '低' },
];

const reaction: Array<TFormOption> = [
  { value: '3', children: '一次面接オファーしたい' },
  { value: '8', children: 'カジュアル面談オファーしたい' },
  { value: '2', children: 'マイナビワークスに相談したい' },
  { value: '4', children: '見送りたい' },
];

/**
 * ポートフォリオ公開状態 （プライバシー設定）
 */
const portfolio_status: Array<TFormOption> = [
  { value: 'public', children: 'パスワードなし' },
  { value: 'password', children: 'パスワードつき' },
  { value: 'closed', children: '非公開' },
];

export const FORM_OPTIONS = {
  prefecture,
  experienced_job,
  experienced_year,
  experienced_skill,
  yearly_income,
  companies_count,
  situation,
  business_type,
  final_education,
  change_date,
  preferred_job_type,
  maximum_management_count,
  english_skills,
  skill_level,
  production_duration,
  product_category,
  management_count,
  search_users_sort_type,
  admin_search_users_sort_type,
  gender,
  employment_contract_type,
  employee_amount,
  offer_rate,
  reaction,
  portfolio_status,
};
