import { useState } from 'react';
import * as yup from 'yup';

import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import {
  baseValidation,
  initSearchConditionValuesBase,
  searchConditionNumberPropertiesBase,
} from '@/hooksDirect/useSearchConditionValidation';
import { AdminSearchConditionModel, CommonSCMcExperiencedJobObjectModel } from '@/utils/api-client';
import {
  conversionStringsLikeNumbers,
  conversionValuesForPeriods,
  excludeValuesForSearchCondition,
  objectEquals,
  reduceExperiencedJobArray,
} from '@/utils/utils';

type TReturn = {
  initValues: AdminSearchConditionModel; // フォームの初期値 これと比較して変更があったかどうか判定
  isChanged: boolean; // 変更があれば true なければ false
  searchConditionChangeDetection: (changeData: AdminSearchConditionModel) => void; // 変更判定処理
};

/**
 * Direct + Adminのvalidation設定
 */
const adminBaseValidation = {
  ...baseValidation,
  tel: YUP_VALIDATION.tel,
  birth_date: YUP_VALIDATION.dateRange,
  'birth_date-from': YUP_VALIDATION.date,
  'birth_date-to': YUP_VALIDATION.date,
  last_login: YUP_VALIDATION.dateRange,
  'last_login-from': YUP_VALIDATION.date,
  'last_login-to': YUP_VALIDATION.date,
  registration_date: YUP_VALIDATION.dateRange,
  'registration_date-from': YUP_VALIDATION.date,
  'registration_date-to': YUP_VALIDATION.date,
  last_update_portfolio: YUP_VALIDATION.dateRange,
  'last_update_portfolio-from': YUP_VALIDATION.date,
  'last_update_portfolio-to': YUP_VALIDATION.date,
  last_update_resume: YUP_VALIDATION.dateRange,
  'last_update_resume-from': YUP_VALIDATION.date,
  'last_update_resume-to': YUP_VALIDATION.date,
  last_update_career_history: YUP_VALIDATION.dateRange,
  'last_update_career_history-from': YUP_VALIDATION.date,
  'last_update_career_history-to': YUP_VALIDATION.date,
  offer_amount: YUP_VALIDATION.numberRange(1, 11),
  'offer_amount-from': YUP_VALIDATION.number,
  'offer_amount-to': YUP_VALIDATION.number,
  offer_pickup_amount: YUP_VALIDATION.numberRange(1, 11),
  'offer_pickup_amount-from': YUP_VALIDATION.number,
  'offer_pickup_amount-to': YUP_VALIDATION.number,
  portfolio_item_count: YUP_VALIDATION.numberRange(0, 99),
  published_portfolio_item_count: YUP_VALIDATION.numberRange(0, 7),
  direct_portfolio_item_count: YUP_VALIDATION.numberRange(0, 99),
};

/**
 * 検索条件フォーム用の validation 設定
 */
export const validationSchemaForPost = yup.object().shape({
  ...adminBaseValidation,
});

/**
 * 検索条件編集用の validation 設定
 */
export const validationSchemaForEdit = yup.object().shape({
  search_condition_name: YUP_VALIDATION.required,
  ...adminBaseValidation,
});

/**
 * Adminの初期値
 */
export const initSearchConditionValues: AdminSearchConditionModel = {
  ...initSearchConditionValuesBase,
  user_entry_id: '',
  user_id: '',
  name: '',
  furigana: '',
  email: '',
  portfolio_url: '',
  tel: '',
  address: '',
  birth_date: [],
  last_login: [],
  registration_date: [],
  last_update_portfolio: [],
  last_update_resume: [],
  last_update_career_history: [],
  portfolio_state: '',
  connected_to_mbdirect: undefined,
  mw_status: undefined,
  offer_amount: [],
  offer_pickup_amount: [],
  user_admin_tags: [],
  published_portfolio_item_count: [],
  direct_portfolio_item_count: [],
};

/**
 * 数値に変換する必要がある要素
 */
export const searchConditionNumberProperties = [
  ...searchConditionNumberPropertiesBase,
  'offer_status',
  'connected_to_mbdirect',
  'mw_status',
  'published_portfolio_item_count',
  'direct_portfolio_item_count',
];

/**
 * 期間を変換する必要がある要素
 */
export const searchConditionPeriodsProperties = [
  'age',
  'offer_update_at',
  'birth_date',
  'last_login',
  'registration_date',
  'last_update_portfolio',
  'last_update_resume',
  'last_update_career_history',
  'offer_amount',
  'offer_pickup_amount',
  'career_history_amount',
  'mc_yearly_income',
  'mc_preferred_yearly_income',
  'portfolio_item_count',
  'published_portfolio_item_count',
  'direct_portfolio_item_count',
  'mc_experienced_job',
];

/**
 * マイナビ向けの検索条件が変更されたかチェック
 */
export const useSearchConditionValidation = (): TReturn => {
  const [isChanged, setIsChanged] = useState(false);
  // 検索条件の初期値 仕様書から構造とってくる
  // https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/post-direct-search_condition
  const initValues: AdminSearchConditionModel = initSearchConditionValues;

  /**
   * データに変更が発生したか検出
   */
  const searchConditionChangeDetection = (changeData: AdminSearchConditionModel) => {
    let convertValue = conversionValuesForPeriods(
      changeData as Record<string, unknown>,
      searchConditionPeriodsProperties
    );

    convertValue = conversionStringsLikeNumbers(convertValue, searchConditionNumberProperties);
    if (convertValue.mc_experienced_job) {
      convertValue.mc_experienced_job = reduceExperiencedJobArray(
        convertValue.mc_experienced_job as Array<CommonSCMcExperiencedJobObjectModel>
      );
    }

    // 編集データの場合に不要なプロパティを除外
    convertValue = excludeValuesForSearchCondition(convertValue);

    setIsChanged(
      !objectEquals(initValues as Record<string, unknown>, convertValue as Record<string, unknown>)
    );
  };

  return { initValues, isChanged, searchConditionChangeDetection };
};
