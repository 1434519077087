import '@/styles/pages/Direct/Users/Detail/index.scss';

import { AxiosError } from 'axios';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { BasicInfoComponent } from '@/components/common/Admin/UserDetail/BasicInfo';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BackButton, BaseButton } from '@/componentsAdmin/Parts/Button';
import { FormContainerDropdownMultiple } from '@/componentsAdmin/Parts/Form';
import { TabNavigation } from '@/componentsAdmin/Parts/Navigation';
import { HeaderTitle } from '@/componentsAdmin/Parts/Title/HeaderTitle';
import { AdminUserSectionTags, Cp2Status } from '@/componentsAdmin/User';
import {
  AdminUserDetailPortfolio,
  AdminUserDetailPublicPortfolio,
} from '@/componentsAdmin/Users/IndexSection';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { useDownloadPDF } from '@/hooks/useDownloadPDF';
import { RootState } from '@/reduxAdmin';
import { notificationError } from '@/reduxAdmin/modules/utils';
import {
  ADMINApi,
  AdminMatchboxUserResponse,
  Career,
  Qualification,
  QualificationQTypeEnum,
  ResponseError,
} from '@/utils/api-client';
import { extractAnswersFromPortfolioQuestions } from '@/utils/extractAnswersFromPortfolioQuestions';
import { getOptionChildrenFromValue } from '@/utils/optionValueToChildren';

import { Account } from './account';
import { Notes } from './notes';

export const AdminUser = (): React.ReactElement => {
  // ====================================================================================================
  //
  // HOOKS
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.direct_auth.userInfo);
  const params: { matchbox_id: string } = useParams();
  const methods = useForm();
  const { downloadPDF } = useDownloadPDF();

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [userInfo, setUserInfo] = useState<AdminMatchboxUserResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [portfolio, setPortfolio] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [careerHistory, setCareerHistory] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [resume, setResume] = useState<any>();
  const [activeIndex, setActiveIndex] = useState(0);

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const getUserRes = async () => {
    try {
      const userRes = await new ADMINApi().getAdminUserId(matchboxId);
      setUserInfo(userRes.data);
      setCareerHistory(userRes.data.career_history);
      return userRes.data;
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
  };

  const getUser = async () => {
    try {
      const userRes = await getUserRes();
      if (!userRes) return;
      const pfRes = await new ADMINApi().getAdminPortfolio(userRes.user_info.user_id);
      const resumeRes = await new ADMINApi().getAdminResume(userRes.user_info.user_id);
      setPortfolio(pfRes.data);
      setResume(resumeRes.data);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const matchboxId = useMemo(() => location.pathname.split('/')[3], [location]);

  const finalEducation = useMemo<string>(() => {
    if (!resume) return '';
    if (resume.educations.length === 0) return '';
    const educations = [...resume.educations];
    educations.sort((a, b) => {
      if (a.e_graduate_date && b.e_graduate_date) {
        const dateA = new Date(a.e_graduate_date);
        const dateB = new Date(b.e_graduate_date);

        if (dateA.getTime() < dateB.getTime()) {
          return 1;
        }
        if (dateA.getTime() > dateB.getTime()) {
          return -1;
        }

        return 0;
      }

      return 0;
    });
    return educations[0].e_school_name;
  }, [resume]);

  const charge = useMemo(() => {
    return extractAnswersFromPortfolioQuestions(portfolio, 'b_v2_2');
  }, [portfolio]);

  const tools = useMemo(() => {
    return extractAnswersFromPortfolioQuestions(portfolio, 'b_v2_7');
  }, [portfolio]);

  const experience = useMemo(() => {
    return extractAnswersFromPortfolioQuestions(portfolio, 'b_v2_8');
  }, [portfolio]);

  const backButton = useMemo(() => {
    const normal = {
      href: '/admin/search/',
      text: '一覧へ戻る',
    };
    if (!history.location.state) return normal;
    type TReferrer = {
      referrer: string;
      company_id?: string;
      offer_id?: string;
    };
    const state = history.location.state as TReferrer;
    if (state.referrer === 'candidates') {
      if (state.offer_id) {
        return {
          href: `/admin/companies/${state.company_id}/candidates/${state.offer_id}`,
          text: '戻る',
        };
      }
      return {
        href: `/admin/companies/${state.company_id}/candidates/`,
        text: '一覧へ戻る',
      };
    }
    return normal;
  }, [history.location.state]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (!params.matchbox_id) return;
    (async () => {
      await getUser();
    })();
  }, [matchboxId]);

  useEffect(() => {
    if (!userInfo) return;
    methods.setValue('user_admin_tags', userInfo?.user_admin_tags);
  }, [userInfo]);

  useEffect(() => {
    const lastPath = location.pathname.split('/').reverse()[0];
    switch (lastPath) {
      case 'notes':
        setActiveIndex(1);
        break;
      case 'account':
        setActiveIndex(2);
        break;
      default:
        setActiveIndex(0);
        break;
    }
  }, [location]);

  useEffect(() => {
    const subscription = methods.watch((value) => {
      (async () => {
        await new ADMINApi().patchAdminUserId(matchboxId, value);
      })();
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="求職者情報" />}>
      <>
        <div className="mbx-direct-users-detail">
          <section className="h-48 flex items-center">
            {backButton.href !== '/admin/search/' && (
              <BackButton href={backButton.href}>{backButton.text}</BackButton>
            )}
          </section>
          <div className="mbx-direct-users-detail-user">
            {userInfo && (
              <BasicInfoComponent
                matchboxId={userInfo.matchbox_id}
                lastLogin={Array.from(userInfo.last_login)[0]}
                registrationDate={userInfo.registration_date ? userInfo.registration_date : ''}
                mcInfo={userInfo.mc}
                userInfo={userInfo.user_info}
                userEntryId={userInfo.user_entry_id}
                career={
                  userInfo.career_history &&
                  userInfo.career_history.careers?.find((career: Career) => !career.c_leave_flag)
                }
              />
            )}
            <div className="w-240 space-y-14">
              <Cp2Status user={userInfo} />
              <BaseButton
                className="w-full"
                theme="primary"
                size="m"
                onClick={() => {
                  history.push(`/admin/users/${matchboxId}/offers`);
                }}
              >
                オファー・ピックアップ履歴
              </BaseButton>
              <FormProvider {...methods}>
                <form>
                  <FormContainerDropdownMultiple
                    name="user_admin_tags"
                    placeholder="確認ステータスを選択"
                    selectList={DIRECT_FORM_OPTIONS.userAdminTags}
                  />
                </form>
              </FormProvider>
            </div>
          </div>

          <section className="mt-12">
            <TabNavigation
              align="fit"
              menu={[
                { title: '求職者詳細', url: `/admin/users/${matchboxId}` },
                { title: '社内メモ', url: `/admin/users/${matchboxId}/notes` },
                { title: '求職者アカウント操作', url: `/admin/users/${matchboxId}/account` },
              ]}
              activeIndex={activeIndex}
            />
          </section>
          {activeIndex === 0 && (
            <section className="my-24">
              <table>
                <tbody>
                  <tr>
                    <th className="mbx-admin-form-title">プロフィール</th>
                    <td>
                      <dl className="">
                        <dt>住所</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.user_info.address2}
                          {userInfo?.user_info.address3}
                        </dd>
                        <dt>電話番号</dt>
                        <dd className="mkt_mask_items">{userInfo?.user_info.tel}</dd>
                        <dt>メールアドレス</dt>
                        <dd className="mkt_mask_items">{userInfo?.user_info.email}</dd>
                        <dt>生年月日</dt>
                        <dd className="mkt_mask_items">
                          {dayjs(
                            `${userInfo?.user_info.birth_year}-${userInfo?.user_info.birth_month}-${userInfo?.user_info.birth_day}`
                          ).format('YYYY/M/D')}
                        </dd>
                      </dl>
                    </td>
                  </tr>
                  {/* 企業向けポートフォリオ */}
                  <AdminUserDetailPublicPortfolio userInfo={userInfo} portfolio={portfolio} />
                  {/* ポートフォリオ */}
                  <AdminUserDetailPortfolio
                    userInfo={userInfo}
                    matchboxId={matchboxId}
                    portfolio={portfolio}
                  />
                  <tr>
                    <th className="mbx-admin-form-title">履歴書</th>
                    <td>
                      <div
                        className={cn('flex relative', { 'h-96': userInfo?.last_update_resume })}
                      >
                        <dl>
                          <dt>更新日</dt>
                          <dd>
                            {/* 更新日がない場合は「未作成」 */}
                            {userInfo?.last_update_resume
                              ? dayjs(userInfo?.last_update_resume).format('YYYY/M/D')
                              : '未作成'}
                          </dd>
                        </dl>
                        {/* 更新日がない場合はリンクを表示しない */}
                        {userInfo?.last_update_resume && (
                          <div className="w-224 absolute right-0">
                            <BaseButton
                              className="w-full"
                              theme="secondary"
                              size="s"
                              onClick={() => {
                                window.open(`/admin/resume/${userInfo?.user_info.user_id}`);
                              }}
                            >
                              詳細を見る
                            </BaseButton>
                            <BaseButton
                              className="w-full"
                              theme="secondary"
                              size="s"
                              iconName="Download"
                              download={'resume.pdf'}
                              forceExternal={true}
                              href={`/api/v2/admin/resume/${matchboxId}/download`}
                              onClick={() => {
                                downloadPDF();
                              }}
                            >
                              PDFをダウンロード
                            </BaseButton>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="mbx-admin-form-title">職務経歴書</th>
                    <td>
                      <div
                        className={cn('flex relative', { 'h-96': careerHistory?.latest_update })}
                      >
                        <dl>
                          <dt>更新日</dt>
                          <dd>
                            {/* 更新日がない場合は「未作成」 */}
                            {careerHistory?.latest_update
                              ? dayjs(careerHistory.latest_update).format('YYYY/M/D')
                              : '未作成'}
                          </dd>
                        </dl>
                        {/* 更新日がない場合はリンクを表示しない */}
                        {careerHistory?.latest_update && (
                          <div className="w-224 absolute right-0">
                            <BaseButton
                              className="w-full"
                              theme="secondary"
                              size="s"
                              onClick={() => {
                                window.open(`/admin/cv/${matchboxId}`);
                              }}
                            >
                              詳細を見る
                            </BaseButton>
                            <BaseButton
                              className="w-full"
                              theme="secondary"
                              size="s"
                              iconName="Download"
                              download="career_history.pdf"
                              forceExternal={true}
                              href={`/api/v2/admin/career_history/${matchboxId}/download`}
                              onClick={() => {
                                downloadPDF();
                              }}
                            >
                              PDFをダウンロード
                            </BaseButton>
                          </div>
                        )}
                      </div>
                      <dl>
                        <dt>概要</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc?.mc_description && userInfo.mc.mc_description}
                        </dd>
                        <dt>職歴</dt>
                        <dd>
                          {userInfo?.career_history &&
                            userInfo?.career_history.careers?.map((c: Career, index: number) => (
                              <div key={index}>
                                {c.c_company_name && c.c_visible && (
                                  <div>
                                    {c.c_company_name && c.c_company_name.length !== 0 && (
                                      <span className="mkt_mask_items">{c.c_company_name}/</span>
                                    )}
                                    {c.c_section && c.c_section.length !== 0 && (
                                      <span className="mkt_mask_items">{c.c_section}/</span>
                                    )}
                                    {c.c_post && c.c_post.length !== 0 && (
                                      <span className="mkt_mask_items">{c.c_post}/</span>
                                    )}
                                    <span className="mkt_mask_items">
                                      {dayjs(c.c_join_date).format('YYYY年M月')}〜
                                    </span>
                                    {c.c_leave_flag ? (
                                      <span className="mkt_mask_items">
                                        {dayjs(c.c_leave_date).format('YYYY年M月')}
                                      </span>
                                    ) : (
                                      <span>現在</span>
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                        </dd>
                        <dt>経験社数</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc &&
                            getOptionChildrenFromValue(
                              'companies_count',
                              userInfo?.mc.mc_companies_count
                            )}
                        </dd>
                        <dt>マネジメント経験</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc &&
                            getOptionChildrenFromValue(
                              'maximum_management_count',
                              userInfo?.mc.mc_maximum_management_count
                            )}
                        </dd>
                        <dt>担当内容</dt>
                        <dd className="mkt_mask_items">{charge.join(' / ')}</dd>
                        <dt>経験</dt>
                        <dd className="mkt_mask_items">{experience.join(' / ')}</dd>
                        <dt>使用言語・ツール</dt>
                        <dd className="mkt_mask_items">{tools.join(' / ')}</dd>
                      </dl>
                    </td>
                  </tr>
                  <tr>
                    <th className="mbx-admin-form-title">資格と学歴</th>
                    <td>
                      <dl>
                        <dt>資格・免許</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.career_history &&
                            userInfo?.career_history.qualifications?.map(
                              (q: Qualification, index: number) =>
                                q.q_visible && (
                                  <div key={index}>
                                    {q.q_type === QualificationQTypeEnum.Text ? (
                                      <>
                                        {dayjs(q.q_date).format('YYYY年M月')} {q.q_title}
                                      </>
                                    ) : (
                                      (parseInt(q.q_score) > 0 || isNaN(Number(q.q_score))) && (
                                        <>
                                          {q.q_type === 'toeic' && (
                                            <>
                                              {dayjs(q.q_date).format('YYYY年M月')} {'TOEIC'}{' '}
                                              {q.q_score}
                                              点取得
                                            </>
                                          )}
                                          {q.q_type === 'toefl' && (
                                            <>
                                              {dayjs(q.q_date).format('YYYY年M月')} {'TOEFL'}{' '}
                                              {q.q_score}
                                              点取得
                                            </>
                                          )}
                                        </>
                                      )
                                    )}
                                  </div>
                                )
                            )}
                        </dd>
                        <dt>英語力</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc &&
                            getOptionChildrenFromValue(
                              'english_skills',
                              userInfo?.mc.mc_english_skills
                            )}
                        </dd>
                        <dt>最終学歴・学校</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc &&
                            getOptionChildrenFromValue(
                              'final_education',
                              userInfo?.mc.mc_final_education
                            )}
                          {finalEducation && `/ ${finalEducation}`}
                        </dd>
                      </dl>
                    </td>
                  </tr>
                  <tr>
                    <th className="mbx-admin-form-title">希望条件</th>
                    <td>
                      <dl>
                        <dt>希望職種</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc &&
                            getOptionChildrenFromValue(
                              'preferred_job_type',
                              userInfo?.mc.mc_preferred_job_type
                            )}
                        </dd>
                        <dt>希望業種</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc &&
                            getOptionChildrenFromValue(
                              'business_type',
                              userInfo?.mc.mc_preferred_business_type
                            )}
                        </dd>
                        <dt>希望勤務地</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc && userInfo?.mc.mc_preferred_prefecture
                            ? userInfo?.mc.mc_preferred_prefecture
                                .map((prefecture: number) =>
                                  getOptionChildrenFromValue('prefecture', prefecture)
                                )
                                .join(' / ')
                            : []
                                .map((prefecture: number) =>
                                  getOptionChildrenFromValue('prefecture', prefecture)
                                )
                                .join(' / ')}
                        </dd>
                        <dt>希望年収</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc &&
                            getOptionChildrenFromValue(
                              'yearly_income',
                              userInfo?.mc.mc_preferred_yearly_income
                            )}
                        </dd>
                        <dt>備考</dt>
                        <dd className="mkt_mask_items">
                          {userInfo?.mc && userInfo?.mc.mc_preferred_reason}
                        </dd>
                      </dl>
                    </td>
                  </tr>
                  <AdminUserSectionTags matchboxId={matchboxId} />
                </tbody>
              </table>
            </section>
          )}
          {activeIndex === 1 && (
            <Notes matchboxId={matchboxId} authorId={user?.user_info.matchbox_id} />
          )}
          {activeIndex === 2 && (
            <Account
              matchboxId={matchboxId}
              authorId={user?.user_info.matchbox_id}
              user={userInfo}
              asyncCallback={getUser}
            />
          )}
        </div>
      </>
    </AdminPage>
  );
};
