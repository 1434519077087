import React from 'react';

import { BaseButton } from '@/components/common/Button/';
import { ModalDefault } from '@/components/common/Modal/Default';
import { useDownloadPDFForPortfolio } from '@/hooks/useDownloadPDFForPortfolio';

type TProps = {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * PDFダウンロード時のモーダル表示
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A174792&t=pjyz7dwyytBC9WJA-4
 */
export const PDFDownLoadModal = ({
  isOpen,
  onClose,
  onClickCancel,
}: TProps): React.ReactElement => {
  const { requestToGeneratePDF } = useDownloadPDFForPortfolio();
  return (
    <ModalDefault
      isOpen={isOpen}
      onClose={() => onClose(false)}
      className="w-784 sm:w-screen lg:px-80 md:px-80 rounded-4 sm:rounded-none"
    >
      <div className="lg:p-80 md:p-80 sm:py-48 sm:px-24">
        <h1 className="text-heading_4 sm:text-heading_3 font-body font-bold mb-24">
          ポートフォリオPDFをダウンロード
        </h1>
        <p className="mbx-typography--body_1 font-body mb-24">
          ダウンロードの準備には、長い場合数十分以上かかります。準備が完了し、ダウンロードできるようになると画面上でお知らせします。またPDFのダウンロードが完了するまで端末やブラウザは変更しないでください。
        </p>
        <div className="flex justify-between sm:flex-col-reverse">
          <div className="sm:w-104">
            <BaseButton onClick={onClickCancel} size="m-for-sp" theme="secondary">
              キャンセル
            </BaseButton>
          </div>

          <BaseButton
            onClick={() => {
              requestToGeneratePDF();
              onClose(false);
            }}
            className="sm:w-full sm:mb-8"
            size="m-for-sp"
            theme="primary"
          >
            ダウンロードの準備を開始する
          </BaseButton>
        </div>
      </div>
    </ModalDefault>
  );
};
