import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import React, {
    ChangeEventHandler, MouseEventHandler, ReactNode, useEffect, useRef, useState, VFC
} from 'react';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormDropdownMultipleBase } from '@/components/common/Form/Dropdown/Multiple/Base';
import {
    FormDropdownMultipleSelectItem
} from '@/components/common/Form/Dropdown/Multiple/SelectItems';
import { FormFootnote } from '@/components/common/Form/Footnote';

import type { BodyScrollOptions } from 'body-scroll-lock';

type TBaseProps = React.ComponentProps<typeof FormDropdownMultipleBase>;

type TProps = {
  name: string;
  id?: string;
  placeholder?: string | number;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  debugError?: boolean;
  selectList: TBaseProps['selectList'];
  defaultValue?: Array<string>;
};
type TSelectBoxProps = {
  children: ReactNode;
};

const SelectBox: VFC<TSelectBoxProps> = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('selectOptionPortal') as HTMLElement
  );
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=393%3A41765
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 */
export const FormContainerDropdownMultiple: VFC<TProps> = ({
  id,
  placeholder,
  name,
  className = '',
  readOnly = false,
  disabled = false,
  debugError = false,
  selectList,
  defaultValue = [],
}) => {
  const methods = useFormContext();
  const watchValue = methods.watch(name);
  const defaultClass = 'mbx-formContainer mbx-formContainer--dropdown';
  const error = methods.formState.errors[name];
  const [showSelectList, setShow] = useState(false);
  const [pos, setPos] = useState({ width: '300px', top: '0px', bottom: 'auto', left: '0px' });
  const [value, setSelectValue] = useState(defaultValue);
  const selectBoxRef = useRef<HTMLDivElement>(null);
  const isTouchDevice = () => window.ontouchstart === null;
  const divSelectItemBoxRef = React.createRef<HTMLDivElement>(); // 擬似的に作る選択要素のDOM
  let showFlag = false;
  let openSelect: HTMLSelectElement; // Select要素

  const register = methods.register(name);

  const changeValue: MouseEventHandler<HTMLLIElement> = (e): void => {
    const afterValue = e.currentTarget.dataset.value;
    if (!afterValue && afterValue !== '') {
      new Error('ターゲットが存在しません');
      return;
    }
    const oldValue = methods.getValues(name);
    const stringValue: Array<string> = [];
    let newValue = [];
    oldValue?.forEach((item: string | number) => {
      if (typeof item === 'number') {
        stringValue.push(String(item));
      } else {
        stringValue.push(item);
      }
    });
    if (stringValue.includes(afterValue)) {
      newValue = stringValue.filter((item: string) => item != afterValue);
    } else {
      newValue.push(afterValue);
      newValue = stringValue.concat(newValue);
    }
    methods.setValue(name, newValue, { shouldValidate: true });
    setSelectValue(newValue);
  };

  /**
   * Select要素が変更された際の処理
   * Select要素のvalueをRHFの値に入れる
   */
  const onChangeSelect: ChangeEventHandler<HTMLSelectElement> = (e): void => {
    const options = e.target.options;
    const selectedOptions = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedOptions.push(options[i].value);
      }
    }
    methods.setValue(name, selectedOptions, { shouldValidate: true });
    setSelectValue(selectedOptions);
  };

  /**
   * 複数選択要素の削除ボタンをクリックした際の処理
   */
  const onDeleteSelectItem: MouseEventHandler<HTMLDivElement> = (e): void => {
    const deleteValue = e.currentTarget.dataset.value;
    let oldValue = methods.getValues(name);
    oldValue = oldValue.filter((item: string) => item != deleteValue);
    methods.setValue(name, oldValue, { shouldValidate: true });
    setSelectValue(oldValue);
  };

  /**
   * セレクト要素自体をクリックしたときの処理
   * 選択項目を表示する
   */
  const onClickSelectBox: MouseEventHandler<HTMLDivElement | HTMLSelectElement> = (e): void => {
    if (!isTouchDevice()) {
      const options: BodyScrollOptions = {
        reserveScrollBarGap: true,
      };
      disableBodyScroll(document.body, options);
      openSelect = e.target as HTMLSelectElement;
      const targetRect = openSelect.getBoundingClientRect();
      if (
        targetRect.top + window.pageYOffset + targetRect.height + 324 <
        document.body.clientHeight
      ) {
        setPos({
          width: targetRect.width + 'px',
          top: targetRect.bottom + 1 + 'px',
          bottom: 'auto',
          left: targetRect.left + 'px',
        });
      } else {
        setPos({
          width: targetRect.width + 'px',
          top: 'auto',
          bottom: window.innerHeight - targetRect.top + 1 + 'px',
          left: targetRect.left + 'px',
        });
      }
      setShow(true);
      showFlag = true;
    }
  };

  /**
   * リサイズした時に位置を再計算する処理
   */
  const resizeAction = () => {
    if (showFlag) {
      const targetRect = openSelect.getBoundingClientRect();
      if (targetRect.top + window.pageYOffset + 300 < document.body.clientHeight) {
        setPos({
          width: targetRect.width + 'px',
          top: targetRect.bottom + 1 + 'px',
          bottom: 'auto',
          left: targetRect.left + 'px',
        });
      } else {
        setPos({
          width: targetRect.width + 'px',
          top: 'auto',
          bottom: window.innerHeight - targetRect.top + 1 + 'px',
          left: targetRect.left + 'px',
        });
      }
    }
  };

  /**
   * RHFの値の変更を監視
   */
  useEffect(() => {
    if (watchValue) {
      if (watchValue.length) {
        const stringValue: Array<string> = [];
        watchValue.forEach((item: string | number) => {
          if (typeof item === 'number') {
            stringValue.push(String(item));
          } else {
            stringValue.push(item);
          }
        });
        setSelectValue(stringValue);
      } else {
        setSelectValue([]);
      }
    } else {
      setSelectValue([]);
    }
  }, [watchValue]);

  /**
   * 選択項目の表示フラグが変更されたら実行する処理
   */
  useEffect(() => {
    // 消える時は処理しない
    if (!showSelectList) return;

    // 仮想のアイテム選択ボックスにfocusを与える
    if (divSelectItemBoxRef.current) {
      divSelectItemBoxRef.current.focus();
    }

    // 表示された時は位置を計算
    const options = document.getElementById('selectOptionPortal') as HTMLElement;
    if (options.children[0] && selectBoxRef.current) {
      const { clientHeight } = options.children[0];
      const bottom = window.innerHeight - selectBoxRef.current?.getBoundingClientRect().bottom;
      if (bottom < clientHeight) {
        setPos({
          width: selectBoxRef.current?.getBoundingClientRect().width + 'px',
          top: 'auto',
          bottom: '10px',
          left: selectBoxRef.current?.getBoundingClientRect().left + 'px',
        });
      }
    }
  }, [showSelectList]);

  useEffect(() => {
    window.addEventListener('resize', resizeAction, {
      capture: false,
      passive: true,
    });

    resizeAction();

    /**
     * コンポーネントが削除されたらイベントリスナーを破棄
     */
    return () => {
      window.removeEventListener('resize', resizeAction);
    };
  }, []);

  return (
    <div className={className}>
      {readOnly ? (
        <div className="mbx-formContainer mbx-formContainer--dropdown--readonly">{watchValue}</div>
      ) : (
        <>
          {showSelectList && (
            <SelectBox>
              <div
                id={name}
                className={cn('mbx-dropdown-wrapper', { 'is-show': showSelectList })}
                style={pos}
                tabIndex={0}
                ref={divSelectItemBoxRef}
                onBlur={(e) => {
                  const relatedTarget = e.relatedTarget as HTMLElement;
                  if (!relatedTarget || !relatedTarget.className.includes('mbx-dropdown-selections')) {
                    setShow(false);
                    showFlag = false;
                    clearAllBodyScrollLocks();
                  }
                }}
              >
                <FormDropdownMultipleBase
                  selectList={selectList}
                  itemClick={changeValue}
                  value={value}
                  parentRef={divSelectItemBoxRef}
                ></FormDropdownMultipleBase>
              </div>
            </SelectBox>
          )}
          <div
            className={cn('mbx-formContainer--dropdown-wrapper', {
              'mbx-formContainer--error': error || debugError,
              'is-show': showSelectList,
            })}
            ref={selectBoxRef}
          >
            <div className="mbx-formContainer--dropdown-multiple-wrapper">
              <select
                className={cn(defaultClass, 'text-white', { hidden: !isTouchDevice() })}
                id={id}
                ref={register.ref}
                name={register.name}
                defaultValue={defaultValue}
                disabled={disabled}
                onClick={onClickSelectBox}
                onChange={onChangeSelect}
                multiple={true}
              >
                <optgroup disabled className={'hidden'}></optgroup>
                {selectList.map((item) => {
                  return (
                    <option
                      value={item.value}
                      key={item.value}
                      className={cn({ hidden: !isTouchDevice() })}
                    >
                      {item.children}
                    </option>
                  );
                })}
              </select>
              <div className="mbx-formContainer--dropdown-arrow">
                <ButtonIconButton
                  type="gray"
                  iconName="Arrow_Down"
                  hitArea="mini"
                  focus={false}
                ></ButtonIconButton>
              </div>
              <div
                className={cn({
                  hidden: isTouchDevice(),
                })}
              >
                <div
                  className={cn('mbx-formContainer--dropdown-multiple-container')}
                  onClick={onClickSelectBox}
                ></div>
              </div>
              {placeholder && !value.length && (
                <div className="mbx-formContainer--dropdown-multiple-placeholder">
                  <p>{placeholder}</p>
                </div>
              )}
              <ul className="mbx-formContainer--dropdown-multiple-list">
                {value.map((selectItem) => {
                  return (
                    <FormDropdownMultipleSelectItem
                      value={selectItem}
                      key={selectItem}
                      onClickClose={onDeleteSelectItem}
                    >
                      {selectList.map((item) => {
                        if (item.value == selectItem) {
                          return <span key={'item' + item.value}>{item.children}</span>
                        }
                      })}
                    </FormDropdownMultipleSelectItem>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="flex flex-row">
            {debugError ? (
              <FormFootnote className="text-error-700">
                エラー表示サンプルエラー表示サンプルエラー表示サンプル
              </FormFootnote>
            ) : (
              <>
                {error ? (
                  <FormFootnote className="text-error-700">
                    {error ? error.message : null}
                  </FormFootnote>
                ) : null}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
