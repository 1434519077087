import { AxiosError } from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { CropperModal } from '@/components/common/Modal/Cropper';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { notificationError, toggleLoading } from '@/redux';
import { State } from '@/redux/state';
import {
  Portfolio,
  PortfolioApi,
  PortfolioItemPostParam,
  ResponseError,
  UserInfo,
  MyNavi,
} from '@/utils/api-client';

import { V2Transfer } from './V2Transfer';
import { OnboardingPage_1 } from './Page1';
import { OnboardingPage_2 } from './Page2';
import { OnboardingPage_3 } from './Page3';
import { OnboardingPage_4 } from './Page4';
import { OnboardingPage_5 } from './Page5';
import { OnboardingPage_6 } from './Page6';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';

type Props = {
  setModalIcon: React.Dispatch<React.SetStateAction<boolean>>;
  terms: boolean;
  descriptionOnly: boolean;
  myNavi?: MyNavi;
};

export const Onboarding = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState<number>(0);
  const [portfolio, setPortfolio] = useState<Portfolio>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buffer, setBuffer] = useState<string>('');
  const mq = useMBXMediaQuery();
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const WIDTH = 944;
  const HEIGHT = 712;
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    document.cookie = 'onboarding=done';
  }, []);

  useEffect(() => {
    if (props.descriptionOnly) {
      setPage(4);
    } else if(props.terms) {
      setPage(1);
    }
  }, [props.terms, props.myNavi]);

  useEffect(() => {
    // 最後のページ以外は閉じるボタンを表示させない
    if (page !== 6) return props.setModalIcon(false);
    props.setModalIcon(true);
  }, [page]);

  useEffect(() => {
    (async () => {
      if (user?.user_id) {
        const res = await new PortfolioApi().getPortfolioEdit(user?.user_id);
        setPortfolio(res.data);
      }
    })();
  }, [user]);

  const imgUpload = async (file: File) => {
    if (!checkFileSize(file.size, 5)) return;
    setIsOpen(true);
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === 'string') setBuffer(reader.result);
    };
    // 画像の読み込み
    reader.readAsDataURL(file);
  };

  const onCropped = async (file: File) => {
    if (!portfolio) return;
    dispatch(toggleLoading(true));
    try {
      const clone = Object.assign(portfolio);
      const data = await new PortfolioApi().postFiles(file);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      const obj = {
        i_description: '',
        i_pages: [],
        i_visible: 1,
        i_start_date: null,
        i_image: {
          f_id: data.data.f_id,
          f_url: data.data.f_url,
          f_thumbnail: data.data.f_thumbnail,
        },
        i_end_date: null,
        i_title: '',
        i_tags: [],
        i_id: null,
        i_embedlink: null,
        i_url: '',
      };
      if (clone?.items) {
        clone.items.push(obj);
      }
      if (user?.user_id) {
        await new PortfolioApi().patchPortfolioEdit(user?.user_id, clone);
      }
      history.push('/mypage/portfolio/edit');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      setIsOpen(false);
    }
  };

  const postPortfolio = async (url: string) => {
    const postData: PortfolioItemPostParam = {
      url: url,
    };
    dispatch(toggleLoading(true));
    try {
      await new PortfolioApi().postPortfolioItem(postData);
      history.push('/mypage/portfolio/edit');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <section className="max-w-784 w-784 md:w-screen sm:w-screen sm:h-auto overflow-hidden">
      <>
        {!props.terms && page === 0 && <V2Transfer page={page} setPage={setPage} />}
        {page === 1 && <OnboardingPage_1 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 2 && <OnboardingPage_2 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 3 && <OnboardingPage_3 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 4 && <OnboardingPage_4 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 5 && <OnboardingPage_5 page={page} setPage={setPage} />}
        {page === 6 && <OnboardingPage_6 page={page} setPage={setPage} />}
      </>
      <CropperModal
        isOpen={isOpen}
        width={WIDTH}
        height={HEIGHT}
        src={buffer}
        postImage={onCropped}
        onClose={() => setIsOpen(false)}
      />
    </section>
  );
};
