import { TDictionary } from '@/types/Util';

export const PLACEHOLDER: TDictionary<string> = {
  company_url:
    '採用サイトやコーポレートサイトなど求職者に見て欲しいWebサイトのURLを入力してください',
  company_message:
    '例）当社では、豊富な実績を礎に、大手クライアントからの直案件のWebサイト制作に携わることができます。また創業当時からWebコンサル事業にも注力しており、Webサイトを納品したら終わりではなく、コンサルタントと伴走し、運用面におけるカスタマーサポートを行うことも可能です。高い制作技術だけでなく、顧客の商品・サービスをグロースさせるためのマーケティングスキルも伸ばしていける環境です。経験の深い仲間と共に、切磋琢磨しながら、ご自身の市場価値を高めていきませんか。（最大1000文字）',
  offer_position_name: '例）Webデザイナー',
  offer_position_description:
    '例）コーポレートサイト（受託案件）を中心に、Webサイト制作におけるデザイン領域全般を担当いただきます。また、ロゴ、ブランドガイドライン、ブランドブックなどのブランディングプロジェクトのデザイン業務に携わっていただくこともあります。制作部署はUI/UXデザイナーやフロントエンドエンジニア含めて約15名のメンバーが在籍しており、制作ツールは主にIllustrator、Photoshop、XD、Figma、コミュニケーションツールはslack、管理ツールはBacklogを使用しています。案件ごとに3～4名でチームを組むことが多く、先輩社員との距離も近いため、意見交換がしやすい環境です。',
};
