import dayjs from 'dayjs';

import { CandidateData } from '@/types/DataLayerType';
import { Repeat, TDictionary } from '@/types/Util';
import {
  CareerHistory,
  CommonSCMcExperiencedJobObjectModel,
  DirectUserSearchModel,
  LastLogin,
  MyNavi,
  Offer,
  Qualification,
  QualificationQTypeEnum,
  Reaction,
  Status,
  UserInfo,
  UserPatchRequest,
} from '@/utils/api-client';
import {
  getDirectOptionChildrenFromValue,
  getOptionChildrenFromValue,
} from '@/utils/optionValueToChildren';

/**
 * 数値にカンマを挿入する
 */
export const numSeparate = (num: number): string => {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

/**
 * 日付をフォーマットする
 * @param  {Date}   date     日付
 * @param  {String} [format] フォーマット
 * @return {String}          フォーマット済み日付
 */
export const formatDate = (date: Date, format: string): string => {
  if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
  format = format.replace(/YYYY/g, date.getFullYear().toString());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));

  const match = format.match(/S/g);
  if (match) {
    const milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
    const length = match.length;
    for (let i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
  }
  return format;
};

/**
 * 日付文字列がyyyy/mm/ddかどうが調べる
 */
export const checkDate = (str: string): boolean => {
  if (typeof str == 'string') {
    const result = str.match(/^(\d\d\d\d)\/(\d+)\/(\d+)$/);
    if (result) {
      const y = parseInt(result[1]);
      const m = parseInt(result[2]) - 1;
      const d = parseInt(result[3]);
      const x = new Date(y, m, d);
      return y == x.getFullYear() && m == x.getMonth() && d == x.getDate();
    }
  }
  return false;
};

/**
 * 日付（生年月日）から年齢を算出する
 */
export const ageFromBirthday = (year: number, month: number, day: number): number => {
  const thisYear: number = parseInt(dayjs().format('YYYY'));
  const age = thisYear - year;

  const thisMonth: number = parseInt(dayjs().format('MM'));
  if (month > thisMonth) {
    return age - 1;
  }
  if (month < thisMonth) {
    return age;
  }

  const today: number = parseInt(dayjs().format('DD'));
  if (day > today) {
    return age - 1;
  }
  return age;
};

/**
 * 数値の範囲内存在を判定
 */
export const numberInRange = (start: number, end: number, num: number): boolean => {
  return Math.min(start, end) <= num && num < Math.max(start, end);
};

/**
 * 配列内の要素の順番を変更する
 *
 * @param {any[]} array
 * @param {number} removedIndex 取り除く位置
 * @param {number} addedIndex 追加する位置
 * @return {*}
 */
export const moveArray = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array: any[],
  removedIndex: number,
  addedIndex: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<any> => {
  const copiedFields = array.filter((val, index) => index !== removedIndex);
  const moveField = { ...array[removedIndex] };

  return [...copiedFields.slice(0, addedIndex), moveField, ...copiedFields.slice(addedIndex)];
};

/**
 * オブジェクトの構造が同じかどうか比較
 */
export const objectEquals = (a: Record<string, unknown>, b: Record<string, unknown>): boolean => {
  const aJSON = JSON.stringify(objectSort(a));
  const bJSON = JSON.stringify(objectSort(b));
  return aJSON === bJSON;
};

/**
 * オブジェクトを配列に変換した上でソートしJSON.stringifyで比較できるように加工
 */
export const objectSort = (obj: Record<string, unknown>): [string, unknown][] => {
  // ソートする
  const sorted = Object.entries(obj).sort();

  // valueを調べ、objectならsorted entriesに変換する
  for (const i in sorted) {
    const val = sorted[i][1];
    if (isObject(val)) {
      sorted[i][1] = objectSort(val as Record<string, unknown>);
    }
  }

  return sorted;
};

/**
 * Object判定
 */
export const isObject = (item: unknown): boolean => {
  return typeof item === 'object' && item !== null && !Array.isArray(item);
};

/**
 * Number型の値をSelect要素に指定するとString型に変換されるためリクエストする際に実際の型との
 * 差異が出てしまうので数値相当の文字列を変換処理して返す
 *
 * 返却するObjectの型が外れてしますので利用元では as などを使用し型付けすること
 *
 * 第二引数で変換したいプロパティ名の配列を渡す
 *
 * 空文字列の場合はundefinedに
 */
export const conversionStringsLikeNumbers = (
  obj: Record<string, unknown>,
  targets: Array<string>
): Record<string, unknown> => {
  const result = {} as Record<string, unknown>;
  Object.keys(obj).forEach((key) => {
    if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
      obj[key] = conversionStringsLikeNumbers(obj[key] as Record<string, unknown>, targets);
    }
    if (targets.includes(key)) {
      // 配列だった場合
      if (Array.isArray(obj[key])) {
        // 配列の中身がObjectの場合は中身の値を変更
        if (isObject((obj[key] as Array<unknown>)[0])) {
          result[key] = (obj[key] as Array<TDictionary<string>>).map((objInArray) => {
            const res: TDictionary<number | undefined | (number | undefined)[]> = {};
            Object.keys(objInArray).forEach((k) => {
              if (Array.isArray(objInArray[k])) {
                const objArray = objInArray[k] as unknown;
                res[k] = (objArray as Array<string>).map((str) => {
                  return numeracy(str);
                });
              } else {
                res[k] = numeracy(objInArray[k]);
              }
            });
            return res;
          });
        } else {
          // 配列の中身を全部変更
          result[key] = (obj[key] as Array<string>).map((str) => {
            return numeracy(str);
          });
        }
      } else {
        result[key] = numeracy(obj[key] as string);
      }
    } else {
      result[key] = obj[key];
    }
  });
  return result;
};

const numeracy = (str: string) => {
  if (typeof str === 'number') return str;
  if (str) {
    return Number(str);
  } else {
    return undefined;
  }
};

/**
 * 職種と経験年数の配列で未設定な要素は一つを残して除外する
 */
export const reduceExperiencedJobArray = (
  experiencedJobs: Array<CommonSCMcExperiencedJobObjectModel>
): Array<CommonSCMcExperiencedJobObjectModel> => {
  const returnJobs = experiencedJobs.filter((job) => {
    if (job.job && job.job_year) {
      return job;
    }
  });
  if (returnJobs.length === 0) {
    returnJobs.push({
      job: undefined,
      job_year: [],
    });
  }
  return returnJobs;
};

/**
 * 期間指定要素で便宜上利用していたfrom toのプロパティは除外して戻す
 */
export const conversionValuesForPeriods = (
  obj: Record<string, unknown>,
  targets: Array<string>
): Record<string, unknown> => {
  const result = {} as Record<string, unknown>;

  // 入力用の配列を事前に生成ただしこれだとネストされた値の場合は対応できないので発生したら考える
  targets.forEach((target) => {
    if (!Array.isArray(obj[target])) result[target] = [];
  });

  Object.keys(obj).forEach((key) => {
    // 配列だった場合
    if (targets.includes(key) && Array.isArray(obj[key])) {
      // 配列の中身がObjectの場合は中身の値を変更
      if (isObject((obj[key] as Array<unknown>)[0])) {
        const array = obj[key] as Array<Record<string, unknown>>;
        obj[key] = array.map((value) => {
          const result = {} as Record<string, unknown>;
          Object.keys(value).forEach((valueKey) => {
            const regex = new RegExp(`-(from|to)$`);
            if (!regex.test(valueKey)) {
              if (Array.isArray(value[valueKey])) {
                // null, null だった場合はからの配列に変換
                const everyNone = (value[valueKey] as Array<string>).every(
                  (v) => v === null || v === undefined || v === ''
                );
                result[valueKey] = everyNone ? [] : value[valueKey];
              } else {
                result[valueKey] = value[valueKey];
              }
            }
          });
          return result;
        });
      }
    }

    if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
      obj[key] = conversionValuesForPeriods(obj[key] as Record<string, unknown>, targets);
    }
    const targetCondition = targets.find((target) => {
      const regex = new RegExp(`^${target}-(from|to)$`);
      return regex.test(key);
    });
    if (!targetCondition) {
      result[key] = obj[key];
    }
  });

  // null, null だった場合はからの配列に変換
  targets.forEach((target) => {
    const everyNone = (result[target] as Array<string>).every(
      (v) => v === null || v === undefined || v === ''
    );
    if (everyNone) {
      result[target] = [];
    }
  });
  return result;
};

/**
 * クエリパラメータを object 形式で取得する
 * @param {string} search useLocation().search
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getQueryParams = (search: string): any => {
  const queryStr = search.substring(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let query: any = {};

  queryStr.split('&').forEach((str: string) => {
    const temp = str.split('=');
    query = {
      ...query,
      [temp[0]]: temp[1],
    };
  });

  return query;
};

/**
 * <img>要素 → Base64形式の文字列に変換
 */
export const ImageToBase64 = (img: HTMLImageElement, mime_type = 'image/png'): string => {
  // New Canvas
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  // Draw Image
  const ctx = canvas.getContext('2d');
  if (ctx) ctx.drawImage(img, 0, 0);
  // To Base64
  return canvas.toDataURL(mime_type);
};

/**
 * idやtitleなど編集時に含まれる項目は変更検知項目から除外
 */
export const excludeValuesForSearchCondition = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  if (hasProperty(obj, 'id')) {
    delete obj.id;
  }
  if (hasProperty(obj, 'search_condition_name')) {
    delete obj.search_condition_name;
  }

  if (hasProperty(obj, 'result')) {
    delete obj.result;
  }

  return obj;
};

/**
 * ESLint対策
 * https://eslint.org/docs/rules/no-prototype-builtins
 */
export const hasProperty = (obj: unknown, key: string): boolean => {
  return isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key);
};

/**
 * MyNaviデータから進捗率を算出する
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const progressFromMyNavi = (formValue: MyNavi) => {
  let itemCount = 0;
  (Object.keys(formValue) as (keyof MyNavi)[]).forEach((item) => {
    if (item === 'mc_connected') return;
    if (item === 'mc_preferred_prefecture' && formValue[item] && formValue[item].length > 0) {
      itemCount++;
    }
    if (item !== 'mc_preferred_prefecture' && formValue[item]) {
      itemCount++;
    }
  });
  return Math.trunc((itemCount / (Object.keys(formValue).length - 1)) * 100);
};

/**
 * ユーザー情報から基本情報データ生成
 */
export const basicInfoFromUserInfo = (user: UserInfo | null | undefined): UserPatchRequest => ({
  name: user?.name || '',
  furigana: user?.furigana || '',
  prefecture: user?.prefecture,
  tel: user?.tel,
  birth_year: user?.birth_year || 1970,
  birth_month: user?.birth_month || 1,
  birth_day: user?.birth_day || 1,
  icon: user?.icon,
  matchbox_id: user?.matchbox_id || '',
});

/**
 * 基本情報データから進捗率を算出する
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const progressFromBasicInfo = (
  basicInfo: UserPatchRequest,
  user: UserInfo | null | undefined
) => {
  let itemCount = 0;
  (Object.keys(basicInfo) as (keyof UserPatchRequest)[]).forEach((item) => {
    if (user?.[item]) itemCount++;
  });
  if (user?.icon) itemCount++;
  return Math.trunc((itemCount / (Object.keys(basicInfo).length + 1)) * 100);
};

export const progressFromCareerHistory = (
  fetchData: (CareerHistory & Status & UserInfo) | undefined
): Repeat<0 | 1, 4> => {
  const description = !!fetchData?.description;
  //スコアがある or Toefl/Toiec以外でタイトルに記述がある場合を入力済とみなす
  const qualification =
    fetchData?.qualifications &&
    (fetchData?.qualifications.filter(
      (q: Qualification) => q.q_type === QualificationQTypeEnum.Toefl && parseInt(q.q_score) > 0
    ).length > 0 ||
      fetchData?.qualifications.filter(
        (q: Qualification) => q.q_type === QualificationQTypeEnum.Toeic && parseInt(q.q_score) > 0
      ).length > 0 ||
      fetchData?.qualifications.filter(
        (q: Qualification) => q.q_type === QualificationQTypeEnum.Text && q.q_title !== ''
      ).length > 0);

  const skill: boolean =
    fetchData?.skills !== undefined &&
    fetchData?.skills.length > 0 &&
    !!fetchData?.skills[0].name &&
    !!fetchData?.skills[0].level &&
    !!fetchData?.skills[0].experience;

  const pr = !!fetchData?.pr;

  return [description ? 1 : 0, qualification ? 1 : 0, skill ? 1 : 0, pr ? 1 : 0];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const percentFromProgress = (progress: Repeat<0 | 1, 4 | 10>) => {
  return Math.floor((progress.filter((x) => x === 1).length * 100) / progress.length);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const progressPercent = (data: number[]) => {
  return Math.floor((data.filter((x) => x === 1).length * 100) / data.length);
};

/**
 * CandidateDataを算出する
 */
export type UserInfoInput = {
  gender?: number;
  birth_year?: number;
  birth_month?: number;
  birth_day?: number;
  prefecture?: number;
};

export type CandidateDataFromOffer = {
  matchboxId: string;
  experiencedJob: string;
  pickUpDate: string;
  status: string;
  matchingDegree: string;
  numberOfOpinions: number;
  opinion: {
    offer: number;
    consultation: number;
    pass: number;
  };
  action: string;
};

export const genCandidateDataFromOffer = (offer: Offer): CandidateDataFromOffer => {
  // {候補者情報｜みんなの意見（オファーしたい）の件数},
  const reactionsOffer = offer.reactions
    ? offer.reactions.filter((reaction) => reaction.status === 3).length
    : 0;
  //{候補者情報｜みんなの意見（MWに相談したい）の件数},
  const reactionsConsultation = offer.reactions
    ? offer.reactions.filter((reaction) => reaction.status === 2).length
    : 0;
  //{候補者情報｜みんなの意見（見送りたい）の件数}
  const reactionsPass = offer.reactions
    ? offer.reactions.filter((reaction) => reaction.status === 4).length
    : 0;

  return {
    matchboxId: offer.matchbox_id || 'N/A',
    experiencedJob:
      getOptionChildrenFromValue('experienced_job', offer.mc_experienced_job) || 'N/A',
    pickUpDate: offer.created_at || 'N/A',
    status: getDirectOptionChildrenFromValue('directSearchStatusForCompany', offer.status) || 'N/A',
    matchingDegree: getOptionChildrenFromValue('offer_rate', offer.rate) || 'N/A',
    numberOfOpinions: offer.reactions ? offer.reactions.length : 0,
    opinion: {
      offer: reactionsOffer,
      consultation: reactionsConsultation,
      pass: reactionsPass,
    },
    action: offer.status !== 1 ? '済' : '未アクション',
  };
};

export const genCandidateData = (
  matchboxId: string | undefined,
  userInfo: UserInfoInput,
  lastLogin: LastLogin,
  registrationDate: string,
  mc: MyNavi,
  userAdminOffers: Offer[],
  userAdminReactions: Reaction[]
): CandidateData | undefined => {
  if (!matchboxId) return;
  const offers = userAdminOffers.filter((offer) => offer.matchbox_id === matchboxId);
  const numberOfOpinions = userAdminReactions.filter(
    (reaction) => reaction.matchbox_id === matchboxId
  ).length;
  return {
    matchboxId,
    gender: getOptionChildrenFromValue('gender', userInfo.gender) || 'N/A',
    age: userInfo.birth_year
      ? `${ageFromBirthday(
          userInfo.birth_year,
          userInfo.birth_month || 1,
          userInfo.birth_day || 1
        )}`
      : 'N/A',
    area: getOptionChildrenFromValue('prefecture', userInfo.prefecture) || 'N/A',
    preferredChangeDate:
      getOptionChildrenFromValue('change_date', mc.mc_preferred_change_date) || 'N/A',
    experiencedJob: getOptionChildrenFromValue('experienced_job', mc.mc_experienced_job) || 'N/A',
    experiencedYear:
      getOptionChildrenFromValue('experienced_year', mc.mc_experienced_year) || 'N/A',
    yearlyIncome: getOptionChildrenFromValue('yearly_income', mc.mc_yearly_income) || 'N/A',
    registrationDate,
    lastLogin: lastLogin.login_date,
    pickUpDate: offers.length ? offers[0].created_at || 'N/A' : 'N/A',
    status: offers.length
      ? getDirectOptionChildrenFromValue('directSearchStatusForCompany', offers[0].status) || 'N/A'
      : 'N/A',
    matchingDegree: offers.length
      ? getOptionChildrenFromValue('offer_rate', offers[0].rate) || 'N/A'
      : 'N/A',
    numberOfOpinions,
    opinion: {
      offer: userAdminReactions.filter((reaction) => reaction.status === 3).length, // {候補者情報｜みんなの意見（オファーしたい）の件数},
      consultation: userAdminReactions.filter((reaction) => reaction.status === 2).length, //{候補者情報｜みんなの意見（MWに相談したい）の件数},
      pass: userAdminReactions.filter((reaction) => reaction.status === 4).length, //{候補者情報｜みんなの意見（見送りたい）の件数}
    },
    action: offers.length ? (offers[0].status !== 1 ? '済' : '未アクション') : 'N/A',
  };
  // { value: '3', children: 'オファーしたい' },
  // { value: '2', children: 'MWに相談したい' },
  // { value: '4', children: '見送りたい' },
};

export const genCandidateDataFromDirectUserSearchModel = (
  user: DirectUserSearchModel
): CandidateData | undefined => {
  const userInfo = user.user_info;
  const lastLogin = Array.from(user.last_login)[0];
  const registrationDate = user.registration_date || '';
  const mc = user.mc as MyNavi;
  const userAdminOffers = user.offers as Offer[];

  return genCandidateData(
    user.matchbox_id,
    userInfo as UserInfoInput,
    lastLogin,
    registrationDate,
    mc,
    userAdminOffers,
    []
  );
};
