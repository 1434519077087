import React, { useMemo } from 'react';

import { DataLayerPushContainer } from '@/componentsDirect/common/DataLayerPushContainer';
import { GlobalLoading } from '@/componentsDirect/Global/Loading';
import { GlobalNotificationErrorPortal } from '@/componentsDirect/Global/NotificationErrorPortal';
import {
    GlobalNotificationSuccessPortal
} from '@/componentsDirect/Global/NotificationSuccessPortal';
import { DirectFooter } from '@/componentsDirect/Layout/Footer';
import { AdminHeader } from '@/componentsDirect/Layout/Header';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';

type TProps = {
  children?: React.ReactElement;
  header?: React.ReactElement;
  sticky?: React.ReactElement;
  isErrorPage?: boolean;
  disableDataLayerPush?: boolean;
};

/**
 * DirectとAdminでは返ってくるAPIの型構造が違うため分岐処理で対応すると可動性が悪いので切り分けちゃう
 * ここはDirectのディレクトリ切ってそこに格納しDirectPageにリネームしたいが他の人の作業に影響しそう
 * なので一旦このままで進める。
 */
export const AdminPage = ({
  children,
  header,
  sticky,
  isErrorPage,
  disableDataLayerPush,
}: TProps): React.ReactElement => {
  const data = useMemo<DataLayerType | undefined>(() => {
    if (!disableDataLayerPush) {
      const data: DataLayerType = {
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
      };
      if (isErrorPage) data.errorStatus = 'error';
      return data;
    }
    return undefined;
  }, [disableDataLayerPush]);

  return (
    <DataLayerPushContainer data={data}>
      <div className="min-h-screen relative pb-120">
        <div>
          <AdminHeader />
          {header}
        </div>
        <main>
          <div className={'w-960 m-auto'}>{children}</div>
          {sticky}
        </main>
        <DirectFooter />
      </div>
      <GlobalLoading />
      <GlobalNotificationErrorPortal />
      <GlobalNotificationSuccessPortal />
    </DataLayerPushContainer>
  );
};
