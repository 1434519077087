import '@/styles/pages/Portfolio/TopEdit.scss';

import arrayMove from 'array-move';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import cn from 'classnames';

import { BaseButton, ButtonExtendedFloatingActionButton } from '@/components/common/Button/';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { CropperModal } from '@/components/common/Modal/Cropper';
import * as Page from '@/components/common/Page';
import {
    HeaderImage, PortfolioHeaderEdit, PortfolioNavigation
} from '@/components/common/Portfolio/';
import { SpEditModal } from '@/components/common/Portfolio/Edit/SpEditModal';
import { WorkAddModal } from '@/components/common/Portfolio/Edit/WorkAddModal';
import { WorkListSection } from '@/components/common/Portfolio/Edit/WorkListSection';
import { WorksRemoveModal } from '@/components/common/Portfolio/Edit/WorkRemoveModal';
import { WorkTypeSelectModal } from '@/components/common/Portfolio/Edit/WorkTypeSelectModal';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import {
    notificationError, notificationSuccess, setDataLayer, setDataLayerChangedBy, toggleLoading,
    userInfoForceUpdate
} from '@/redux';
import { State } from '@/redux/state';
import {
    Portfolio, PortfolioApi, PortfolioItem, PortfolioItemPostParam, ResponseError, UserApi,
    UserInfo, UserPatchRequest
} from '@/utils/api-client';
import { progressPercent } from '@/utils/utils';

function TopEdit(): React.ReactElement {
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [removePortfolioIndex, setRemovePortfolioIndex] = useState<number | null>(null);
  const [portfolioTypeModal, setPortfolioTypeModal] = useState<boolean>(false);
  const [portfolioAddModal, setPortfolioAddModal] = useState<boolean>(false);
  const [selectedTypeContents, setSelectedTypeContents] = useState<string[]>(['', '']);
  const [portFolio, setPortFolio] = useState<Portfolio>();
  const [portFolioItems, setPortFolioItems] = useState<PortfolioItem[]>();
  const [onMove, setOnMove] = useState<boolean>(false);
  const [spModal, setSpModal] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const mq = useMBXMediaQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buffer, setBuffer] = useState<string | ArrayBuffer | null>(null);
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    if (user) {
      refresh();
    }
  }, [user?.user_id]);

  const refresh = async () => {
    setPending(false);

    if (!user?.user_id) return;
    const portfolio = await new PortfolioApi().getPortfolioEdit(user?.user_id);
    setPortFolio(portfolio.data);
    if (!portfolio.data.items) return;
    const items: Set<PortfolioItem> = portfolio.data.items;
    setPortFolioItems(Array.from(items?.values()));
    setPending(true);

    return { portfolio, items };
  };

  // バナー画像変更
  const bannerImageUpdate = async (file: string) => {
    dispatch(toggleLoading(true));
    try {
      const f = await new PortfolioApi().postFiles(file);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      if (!portFolio?.profile) return;
      const copy: Portfolio = Object.assign(portFolio);
      if (copy.profile) {
        copy.profile.main_image.f_id = f.data.f_id;
        copy.profile.main_image.f_url = f.data.f_url;
        copy.profile.main_image.f_thumbnail = f.data.f_thumbnail;
      }
      await patchPortfolio(copy);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // バナー画像削除
  const bannerImageDelete = async () => {
    dispatch(toggleLoading(true));
    try {
      if(!portFolio?.profile?.main_image.f_id) return;
      await new PortfolioApi().deleteFiles(portFolio.profile.main_image.f_id);
      const copy: Portfolio = Object.assign(portFolio);
      if (copy.profile) {
        copy.profile.main_image = {} as any;
      }
      await patchPortfolio(copy);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  //ユーザー画像変更
  const userImageUpdate = async (src: string | null) => {
    if (!user) return;

    // ユーザー情報編集APIの必須項目対応で便宜上ここでは不要な情報もリクエスト
    const data: UserPatchRequest = {
      name: user.name,
      furigana: user.furigana,
      birth_year: user.birth_year,
      birth_month: user.birth_month,
      birth_day: user.birth_day,
      icon: src as any,
      matchbox_id: user.matchbox_id as string,
    };

    dispatch(toggleLoading(true));
    try {
      const user = await new UserApi().patchUser(data);
      if (user.data.user_info) {
        dispatch(userInfoForceUpdate(user.data.user_info));
        push({
          event: 'fileUpload',
          actionType: 'file_upload',
          actionName: '画像',
        });
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  useEffect(() => {
    if (!onMove) return;
    if (!portFolio?.items) return;
    const copy = Object.assign(portFolio);
    copy.items = portFolioItems;
    patchPortfolio(copy);
    setOnMove(false);
  }, [onMove]);

  //ドラック&ドロップ
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id && portFolioItems) {
      const oldIndex = Array.from(portFolioItems.values())
        .map((item) => item.i_id)
        .indexOf(parseInt(active.id));
      const newIndex = Array.from(portFolioItems.values())
        .map((item) => item.i_id)
        .indexOf(parseInt(over.id));
      setPortFolioItems((itemsArray) => {
        if (!itemsArray) return;
        return arrayMove(itemsArray, oldIndex || 0, newIndex || 0);
      });
      setOnMove(true);
    }
  };

  //削除モーダルOPEN
  const removeModalOpen = (index: number) => {
    setRemovePortfolioIndex(index);
    setRemoveModal(true);
  };

  //カード削除
  const removePortfolio = async () => {
    if (removePortfolioIndex === null) return;
    if (!portFolioItems) return;
    dispatch(toggleLoading(true));
    const i_id: number = Array.from(portFolioItems.values())[removePortfolioIndex].i_id;
    try {
      await new PortfolioApi().deletePortfolioItem(i_id.toString());
      refresh();
      setRemovePortfolioIndex(null);
      setRemoveModal(false);
      dispatch(notificationSuccess('作品を削除しました'));
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  // 追加する作品選択 画像
  const selectedTypeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPortfolioTypeModal(false);
    if (!e.target.files) return;
    const imageFile = e.target.files[0];
    if (!checkFileSize(imageFile.size, 5)) return;
    setIsOpen(true);
    const reader = new FileReader();

    reader.onload = () => {
      setBuffer(reader.result);
    };

    // 画像の読み込み
    reader.readAsDataURL(imageFile);
  };

  const onCropped = async (imageFile: File) => {
    if (!portFolio || !portFolioItems) return;
    dispatch(toggleLoading(true));
    try {
      const uploadedFile = await new PortfolioApi().postFiles(imageFile);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      const copyPortfolio: Portfolio = { ...portFolio };
      const copyItems = Object.assign([...portFolioItems]);
      const iVisibleFlag = (copyItems.filter((item: PortfolioItem) => item.i_visible == true).length < 6) ? 1 : 0;
      const newItem = {
        i_description: '',
        i_pages: [],
        i_visible: iVisibleFlag,
        i_start_date: null,
        i_image: {
          f_id: uploadedFile.data.f_id,
          f_url: uploadedFile.data.f_url,
          f_thumbnail: uploadedFile.data.f_thumbnail,
        },
        i_end_date: null,
        i_title: '',
        i_tags: [],
        i_id: null,
        i_embedlink: null,
        i_url: '',
      };
      copyItems.push(newItem);
      copyPortfolio.items = copyItems;
      const id = user?.user_id;
      if (!id) return;
      await new PortfolioApi().patchPortfolioEdit(id, copyPortfolio);
      await refresh();
      // TODO: PATCH後に新しいPortfolioItem[]が返ってこないので自動遷移を停止する
      // const res = await refresh();
      // if (res) {
      //   const i_id = Array.from(res.items.values())[Array.from(res.items.values()).length - 1]
      //     .i_id;
      //   linkTo(i_id);
      // }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      setPortfolioTypeModal(false);
      dispatch(toggleLoading(false));
      setIsOpen(false);
    }
  };

  const linkTo = (id: number) => {
    const user_id = user?.user_id;
    if (!user_id) return;
    history.push(`/mypage/portfolio/${user_id}/${id}/edit`);
  };

  //i_visible更新
  const onVisible = (id: number) => {
    if (!portFolio) return;
    const items = Object.assign(portFolio);
    items?.items.map((item: PortfolioItem) => {
      if (item.i_id === id) {
        item.i_visible = !item.i_visible;
      }
    });
    patchPortfolio(items, id);
  };

  //作品タイプ選択に戻る
  const returnModal = () => {
    setPortfolioAddModal(false);
    setPortfolioTypeModal(true);
  };

  // 作品POST
  const postItemApi = async (url: string) => {
    dispatch(toggleLoading(true));
    // 追加するURL
    const postData: PortfolioItemPostParam = {
      url: url,
    };

    try {
      await new PortfolioApi().postPortfolioItem(postData);
      const res = await refresh();
      dispatch(setDataLayerChangedBy('top_edit'));
      const items = res?.items ? Array.from(res?.items) : [];
      const progress = [];
      for (const item of items) {
        const user_id = user?.user_id;
        if (user_id) {
          const res = await new PortfolioApi().getPortfolioProgressDetail(user_id, `${item.i_id}`);
          progress.push(progressPercent(res.data.progress));
        }
      }
      dispatch(
        setDataLayer({
          portfolioItemNum: `${items.length}`,
          portfolioCompleteness: `${progress?.join('|')}`,
        })
      );

      // TODO: POST後に新しいPortfolioItem[]が返ってこないので自動遷移を停止する
      // const res = await refresh();
      // if (res) {
      //   const i_id = Array.from(res.items.values())[Array.from(res.items.values()).length - 1].i_id;
      //   linkTo(i_id);
      // }
      setPortfolioAddModal(false);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  //ポートフォリオPATCH
  const patchPortfolio = async (payload: Portfolio, visibleId?: number) => {
    const id = user?.user_id;
    if (!id) return;
    try {
      await new PortfolioApi().patchPortfolioEdit(id, payload);
      setPortFolio(payload);
      if (payload.items) {
        setPortFolioItems(Array.from(payload.items?.values()));
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
      if (e.response?.status === 400 && payload.items) {
        const items = Object.assign(payload);
        items.items.map((item: PortfolioItem) => {
          if (item.i_id === visibleId) {
            item.i_visible = false;
          }
        });
        setPortFolio(items);
        setPortFolioItems(Array.from(items.items.values()));
      }
    }
  };

  const style = portFolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="portfolio-edit">
      <Page.Wrapper
        header={
          <>
            <div className={cn({"sticky top-0 z-20": !mq.sm})}>
              <HeaderTitle title="ポートフォリオの編集">
                <div className="portfolio-edit-preview-wrapper" style={style}>
                  {portFolio?.updated_at && (
                    <p className="text-gray-700 text-10_10">最終更新：{portFolio?.updated_at}</p>
                  )}
                  <div className="ml-16 w-140 sp_none">
                    {user && (
                      <BaseButton
                        theme="secondary"
                        size="40"
                        href={`/mypage/portfolio/${user.user_id}`}
                        className="w-full whitespace-nowrap"
                      >
                        プレビュー
                      </BaseButton>
                    )}
                  </div>
                </div>
              </HeaderTitle>
            </div>
            <HeaderImage
              cameraIcon={true}
              imageSrc={
                portFolio?.profile?.main_image.f_url
                  ? portFolio?.profile?.main_image.f_url
                  : '/images/header-img_default.png'
              }
              onChange={bannerImageUpdate}
              onClick={bannerImageDelete}
            />
          </>
        }
        footer={
          <>
            {user && (
              <section className="sp-edit_btn sp_only mbx-preview-fix-btn-area sm:mb-144">
                <BaseButton
                  theme={'secondary'}
                  size={'m-for-sp'}
                  href={`/mypage/portfolio/${user.user_id}`}
                >
                  プレビューする
                </BaseButton>
              </section>
            )}
          </>
        }
      >
        <div>
          {/* 作品編集モーダルSP */}
          <SpEditModal
            isModal={spModal}
            portfolioItems={portFolioItems}
            onModal={setSpModal}
            handleDragEnd={handleDragEnd}
            removeModalOpen={removeModalOpen}
          />
          {/* 作品削除モーダル */}
          <WorksRemoveModal
            isModal={removeModal}
            onModal={setRemoveModal}
            removePortfolio={removePortfolio}
          />
          {/* 作品タイプ選択モーダル */}
          <WorkTypeSelectModal
            isModal={portfolioTypeModal}
            onModal={setPortfolioTypeModal}
            selectedTypeImage={selectedTypeImage}
            setPortfolioAddModal={setPortfolioAddModal}
            setPortfolioTypeModal={setPortfolioTypeModal}
            setSelectedTypeContents={setSelectedTypeContents}
          />
          {/* 作品追加モーダル */}
          <WorkAddModal
            isModal={portfolioAddModal}
            onModal={setPortfolioAddModal}
            selectedTypeContents={selectedTypeContents}
            postItemApi={postItemApi}
            returnModal={returnModal}
          />
          <PortfolioHeaderEdit
            imageSrc={user?.icon ? user.icon : '/images/circle-placeholder.png'}
            onChange={userImageUpdate}
            portFolio={portFolio}
          />
          <PortfolioNavigation
            menu={[
              { title: '作品', url: '/mypage/portfolio/edit' },
              { title: 'プロフィール', url: `/mypage/portfolio/${user?.user_id}/profile/edit` },
              { title: '設定', url: '/mypage/portfolio/setting' },
            ]}
            activeIndex={0}
          />
          {portFolioItems && portFolioItems.length > 0 && (
            <>
              <p className={cn("text-14_21", mq.sm ? "mt-32" : "mt-48")}>あなたの作品を登録しましょう。<br />登録数に制限なし。最大6作品まで公開できます。</p>
              <section className="sp-edit_btn sp_only mt-18">
                <BaseButton
                  theme={'primary'}
                  size={'s'}
                  iconName={'Add'}
                  onClick={() => setPortfolioTypeModal(true)}
                >
                  作品を追加する
                </BaseButton>
              </section>
              <section className="sp-edit_btn sp_only mb-18">
                <BaseButton
                  theme={'quaternary'}
                  size={'s'}
                  iconName={'Sortable'}
                  onClick={() => setSpModal(true)}
                >
                  並べ替えと削除
                </BaseButton>
              </section>
            </>
          )}
          {pending && (
            <WorkListSection
              portfolioItems={portFolioItems}
              handleDragEnd={handleDragEnd}
              removeModalOpen={removeModalOpen}
              onVisible={onVisible}
              linkTo={linkTo}
              setPortfolioTypeModal={setPortfolioTypeModal}
            />
          )}
        </div>
      </Page.Wrapper>
      <CropperModal
        isOpen={isOpen}
        width={IMAGE_SIZE.pf_main.width}
        height={IMAGE_SIZE.pf_main.height}
        src={buffer}
        postImage={onCropped}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

export default TopEdit;
