import React from 'react';

import {
    FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';

/**
 * 検索条件フォーム 希望条件
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionDesired = (): React.ReactElement => {
  return (
    <UserCondition title="希望条件">
      <div className="mbx-utils-stack-v--22">
        <FormLayoutFieldset className="pl-24">
          <FormLabel label="希望職種" type="small"></FormLabel>
          <FormContainerDropdownMultiple
            name="mc_preferred_job_type"
            placeholder="選択してください（複数選択可）"
            selectList={FORM_OPTIONS.experienced_job}
            className="mkt_non_mask_items"
          ></FormContainerDropdownMultiple>
        </FormLayoutFieldset>

        <FormLayoutFieldset className="pl-24">
          <FormLabel label="希望業種" type="small"></FormLabel>
          <FormContainerDropdownMultiple
            name="mc_preferred_business_type"
            placeholder="選択してください（複数選択可）"
            selectList={FORM_OPTIONS.business_type}
            className="mkt_non_mask_items"
          ></FormContainerDropdownMultiple>
        </FormLayoutFieldset>

        <FormLayoutFieldset className="pl-24">
          <FormLabel label="希望勤務地" type="small"></FormLabel>
          <FormContainerDropdownMultiple
            name="mc_preferred_prefecture"
            placeholder="選択してください（複数選択可）"
            selectList={FORM_OPTIONS.prefecture}
            className="mkt_non_mask_items"
          ></FormContainerDropdownMultiple>
        </FormLayoutFieldset>

        <FormLayoutFieldset className="pl-24">
          <FormLabel label="希望年収" type="small"></FormLabel>
          <FormContainerDropdownMultiple
            name="mc_preferred_yearly_income"
            placeholder="選択してください（複数選択可）"
            selectList={FORM_OPTIONS.yearly_income}
            className="mkt_non_mask_items"
          ></FormContainerDropdownMultiple>
        </FormLayoutFieldset>
      </div>
    </UserCondition>
  );
};
