import React, { useMemo, useState } from 'react';

import { ModalDefault } from '@/components/common/Modal/Default';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DirectCompanyModel } from '@/utils/api-client';
import { convertLineBreak, linkifyUrls } from '@/utils/string';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  company?: DirectCompanyModel;
};

/**
 * 企業からのメッセージをポップアップで表示
 */
export const OffersModalMessageFromCompanyComponent = ({ company }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [showModal, setShowModal] = useState(false);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onOpen: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const companyFormatData = useMemo(() => {
    if (!company)
      return {
        prefecture: '',
        business_type: '',
        employee_amount: '',
      };
    const prefecture = FORM_OPTIONS.prefecture.find(
      (p) => p.value === company.prefecture?.toString()
    )?.children;
    const business_type = FORM_OPTIONS.business_type.find(
      (p) => p.value === company.business_type?.toString()
    )?.children;
    const employee_amount = FORM_OPTIONS.employee_amount.find(
      (a) => a.value === company.employee_amount?.toString()
    )?.children;

    return {
      prefecture,
      business_type,
      employee_amount,
    };
  }, [company]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <>
      {company && (
        <>
          <i
            className="mbx-icon mbx-icon-Message text-icon text-blue-700 cursor-pointer"
            onClick={onOpen}
          ></i>
          <ModalDefault
            isOpen={showModal}
            onClose={onClose}
            className="w-2/3vw rounded-modalContainer"
          >
            <section className="p-80">
              <table className="w-full">
                <tbody className="text-left border-b border-gray-300">
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170">
                      所在地
                    </th>
                    <td className="text-body_2 p-24 font-normal">
                      {companyFormatData.prefecture}
                      {company.address}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170">
                      業種
                    </th>
                    <td className="text-body_2 p-24 font-normal">
                      {companyFormatData.business_type}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170">
                      事業内容
                    </th>
                    <td className="text-body_2 p-24 font-normal">{company.description}</td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170">
                      社員数
                    </th>
                    <td className="text-body_2 p-24 font-normal">
                      {companyFormatData.employee_amount}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170">
                      社員採用担当者からのメッセージ
                    </th>
                    <td
                      className="text-body_2 p-24 font-normal"
                      dangerouslySetInnerHTML={{
                        __html: convertLineBreak(linkifyUrls(company.message)),
                      }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </section>
          </ModalDefault>
        </>
      )}
    </>
  );
};
