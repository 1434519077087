import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { CompanyDescription } from '@/componentsAdmin/Company/Detail/Description';
import { DescriptionWrapper } from '@/componentsAdmin/Company/Detail/DescriptionWrapper';
import { CompanyStaffList } from '@/componentsAdmin/Company/Detail/Staff';
import { ADMINApi, AdminCompany, CompanyUser, Status } from '@/utils/api-client';

type TCompany = {
  company: AdminCompany;
  users: CompanyUser[];
};

export const CompanyDetail = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const params: { company_id: string } = useParams();
  const [company, setCompany] = useState<TCompany>();

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    getCompany();
  }, []);

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const getCompany = async () => {
    const res = await new ADMINApi().getAdminCompany(params.company_id);
    setCompany(res.data as TCompany & Status);
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="pb-80">
      <DescriptionWrapper title="基本情報" className="mt-24 border-t border-gray-300">
        <>
          {company?.company && (
            <CompanyDescription company={company?.company} getCompany={getCompany} />
          )}
        </>
      </DescriptionWrapper>
      <DescriptionWrapper title="社員情報">
        <>
          <div className="flex text-10_24 text-blue-700 font-bold border-b border-blue-700">
            <p className="w-200 ml-16">ユーザー名</p>
            {/* <p className="w-56 ml-48">企業担当ID</p> */}
            <p className="w-148 ml-16">部署</p>
            {/* <p className="w-148 ml-8">役職</p> */}
            <p className="w-81 ml-8">アカウント種別</p>
            <p className="w-168 ml-8">所属グループ</p>
          </div>
          {company?.users.map((user, index) => {
            return (
              <div key={index}>
                <CompanyStaffList user={user} />
              </div>
            );
          })}
        </>
      </DescriptionWrapper>
    </section>
  );
};
