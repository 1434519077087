import '@/styles/pages/Portfolio/TopPreview.scss';

import { AxiosError } from 'axios';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import BaseButton from '@/components/common/Button/BaseButton';
import * as Page from '@/components/common/Page';
import {
    ActionHeader, HeaderImage, Introduction, Navigation, PreviewHeader, Publish, Skills,
    UserThumbNameJob, WorksModule
} from '@/components/common/Portfolio/';
import { PortfolioPDFDownloadButton } from '@/components/common/Portfolio/PDFDownloadButton';
import { PreviewFooter } from '@/components/common/Preview/Footer';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import {
    PortfolioModalAgreedToPublishPortfolio
} from '@/components/Portfolio/ModalAgreedToPublishPortfolio';
import { usePortfolioUser } from '@/hooks/usePortfolioUser';
import Error from '@/pages/Error';
import { Password } from '@/pages/Portfolio';
import { notificationError } from '@/redux/index';
import { State } from '@/redux/state';
import { ResponseError, UserApi } from '@/utils/api-client';
import { PortfolioItem } from '@/utils/api-client/api';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
interface RouteParams {
  matchbox_id: string;
}

function TopPreview(props: {
  presentation?: boolean;
  direct?: boolean;
  admin?: boolean;
}): React.ReactElement {
  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const API = new UserApi();
  const type = props.direct ? 'direct' : props.admin ? 'admin' : 'public';
  const { matchbox_id } = useParams<RouteParams>();
  const history = useHistory();
  const {
    userId,
    portfolio,
    errorCode,
    productPath,
    profilePath,
    setPortfolio,
    getPortfolioWithPassword,
    experiences,
    career,
  } = usePortfolioUser(props.presentation, type, matchbox_id);

  const style = portfolio?.updated_at ? { justifyContent: 'space-between' } : undefined;

  // ====================================================================================================
  //
  // Redux
  //
  // ====================================================================================================
  const stateUserInfo = useSelector((state: State) => state.user);
  const dispatch = useDispatch();

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const [agreedModal, setAgreedModal] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onClickPortfolio = () => {
    // 新仕様未同意でダイレクト利用の場合は同意のモーダルを表示
    if (!isAgreed && stateUserInfo?.use_direct) {
      setAgreedModal(true);
    }

    // 新仕様未同意でダイレクトを利用してない場合はモーダルを表示
    if (!isAgreed && !stateUserInfo?.use_direct) {
      setPublishModal(true);
    }

    // 新仕様同意でダイレクト利用の場合は更新モーダルを表示
    if (isAgreed) {
      setPublishModal(true);
    }
  };

  const linkToEdit = () => history.push(`/mypage/portfolio/edit`);

  const linkToDetail = (id: number) => {
    if (!userId) return;
    let link = `/portfolio/${userId}/${id}/`;
    if (!props.presentation) {
      link = `/mypage/portfolio/${userId}/${id}/`;
    }
    if (props.direct) {
      link = `/direct/portfolio/${userId}/${id}/`;
    }
    if (props.admin) {
      link = `/admin/portfolio/${matchbox_id}/${id}/`;
      if (location.pathname.includes(`/admin/portfolio/`)) {
        link = `/admin/portfolio/${matchbox_id}/${id}/`;
      } else {
        link = `/admin/direct/portfolio/${matchbox_id}/${id}/`;
      }
    }
    history.push(link);
  };

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const getAgreedToPublishPortfolio = async () => {
    try {
      const res = await API.getAgreedToPublishPortfolio();
      return res.data;
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const previewHeader = useMemo(() => {
    if (props.direct) return <h3>MATCHBOX ID：{userId}</h3>;
    if (location.pathname.includes(`/admin/direct/portfolio/`)) {
      return <h3>MATCHBOX ID：{matchbox_id}</h3>;
    }
    if (portfolio?.page_title_logo?.f_url) {
      return <img src={portfolio.page_title_logo.f_url} alt="" />;
    }
    if (portfolio?.page_title && portfolio?.page_title?.length !== 0) {
      return <h3>{portfolio?.page_title}</h3>;
    }

    return '';
  }, [portfolio]);

  const pfItems = useMemo(() => {
    if (!portfolio || !portfolio.items) return [];
    if (props.direct || props.admin) return Array.from(portfolio.items.values());
    return Array.from(portfolio.items.values()).filter((p) => p.i_visible);
  }, [portfolio]);

  const publish = useMemo(() => {
    return portfolio?.publish ? true : false;
  }, [portfolio]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (props.presentation) return;
    (async () => {
      const res = await getAgreedToPublishPortfolio();
      setIsAgreed(!!res?.agreed);
    })();
  }, []);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  // 非公開時のビジュアル
  if (errorCode === '3001' || (portfolio?.status === 'closed' && !portfolio.items)) {
    return <Error {...props} />;
  }

  // パスワード入力画面
  if (!portfolio?.items && errorCode === '3002' && props.presentation) {
    return <Password getPortfolio={getPortfolioWithPassword} />;
  }

  //エラーコードが確定するまで画面は表示しない（不要なdataLayerが送信されるため）
  if (errorCode === null) {
    return <></>;
  }
  return (
    <Page.Wrapper
      forPrint
      presentation={props.presentation}
      header={
        <>
          <div className="mbx-top-preview-header sticky top-0 z-10">
            {!props.presentation && (
              <>
                <HeaderTitle title="プレビュー">
                  <div className="mbx-top-preview-wrapper" style={style}>
                    {portfolio?.updated_at && (
                      <p className="text-gray-700 text-10_10">最終更新：{portfolio?.updated_at}</p>
                    )}
                    <div className="ml-16 space-x-24 flex items-center sp_none">
                      <PortfolioPDFDownloadButton />
                      <div className="w-140 sm:w-88 flex">
                        <BaseButton
                          onClick={linkToEdit}
                          className="mbx-top-preview-edit-button w-full"
                          theme="secondary"
                          size="40"
                        >
                          編集する
                        </BaseButton>
                      </div>
                      <div className={cn(!publish ? 'w-186' : 'w-199', 'sm:w-88 flex')}>
                        <BaseButton
                          iconName={'Publish'}
                          className="filter drop-shadow-floatingButton"
                          onClick={onClickPortfolio}
                          size="40"
                          theme="primary"
                        >
                          {!publish ? 'Webページをつくる' : 'Webページを更新する'}
                        </BaseButton>
                      </div>
                    </div>
                  </div>
                </HeaderTitle>
                <div className="sp_only">
                  <ActionHeader>
                    <>
                      <div className="children">
                        <PortfolioPDFDownloadButton />
                      </div>
                      <div className="children">
                        <div className="w-140 flex">
                          <BaseButton
                            onClick={linkToEdit}
                            className="mbx-top-preview-edit-button w-full"
                            theme="secondary"
                            size="40"
                          >
                            編集する
                          </BaseButton>
                        </div>
                      </div>
                    </>
                  </ActionHeader>
                </div>
              </>
            )}
          </div>
          <PreviewHeader>{previewHeader}</PreviewHeader>
          <HeaderImage
            imageSrc={
              portfolio?.profile?.main_image.f_url
                ? portfolio?.profile?.main_image.f_url
                : '/images/header-img_default.png'
            }
          />
        </>
      }
      footer={
        <>
          {!props.presentation && (
            <section className="sp-edit_btn sp_only mbx-preview-fix-btn-area sm:mb-144">
              <BaseButton
                theme="primary"
                iconName={'Publish'}
                size={'m-for-sp'}
                onClick={onClickPortfolio}
              >
                {!publish ? 'Webページをつくる' : 'Webページを更新する'}
              </BaseButton>
            </section>
          )}
        </>
      }
    >
      <div className={cn('mbx-top-preview', { 'pb-80': props.presentation })}>
        <UserThumbNameJob profile={portfolio?.profile} direct={props.direct} />

        <Navigation
          menu={[
            { title: '作品', url: productPath },
            { title: 'プロフィール', url: profilePath },
          ]}
          activeIndex={0}
        />

        <Page.Column
          main={<Introduction profile={portfolio?.profile} layout="b" />}
          menu={<Skills skills={career?.skills} experiences={experiences} />}
        />

        <ul className="mbx-top-preview-works">
          {pfItems.map((item: PortfolioItem, index: number) => {
            return (
              <li key={index}>
                <WorksModule
                  thumbnail={item.i_image.f_thumbnail}
                  title={item.i_title}
                  category={item.i_tags ? item.i_tags.join(', ') : ''}
                  moveClass={'dragHandleSelector'}
                  onClick={() => linkToDetail(item.i_id)}
                  visible
                  removeClick={() => {
                    console.log('remove');
                  }}
                />
              </li>
            );
          })}
        </ul>

        <PreviewFooter className="folio mt-42" />

        {agreedModal && (
          <PortfolioModalAgreedToPublishPortfolio
            published={portfolio?.publish as number}
            onClose={(agreed: boolean) => {
              setAgreedModal(false);
              if (agreed) {
                setIsAgreed(true);
                setPublishModal(true);
              }
            }}
          />
        )}

        <Publish
          {...{
            portfolio,
            userId,
            publishModal,
            published: publish,
            onClose: () => setPublishModal(false),
            updatePortfolio: (data) => {
              if (data) dispatch(setPortfolio(data));
            },
          }}
        />
      </div>
    </Page.Wrapper>
  );
}

export default TopPreview;
